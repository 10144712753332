import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Layout";
import Img from "../../../components/Img";
import axios from "axios";
import classNames from "classnames";
import ProjectSlider from "../common/ProjectSlider";
import { BeforeAfter } from "../../../components/BeforeAfter";

const ProjectShowcaseView = () => {
  const [projectData, setProjectData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/project-showcases/${id}`);
        setProjectData(response.data);
      } catch (error) {
        console.error("Failed to fetch project data", error);
      }
    };

    fetchProject();
  }, [id]);

  if (!projectData) {
    return null;
  }

  const {
    title,
    subTitle,
    sliderImages,
    spaceSize,
    industry,
    location,
    textSection,
    beforeAfterImages,
    virtualLink,
    youtubeLink,
  } = projectData;

  return (
    <Layout>
      <div className="p-3 xl:max-w-screen-lg xl:px-0 2xl:max-w-screen-xl mx-auto bg-white">
        <div className="mb-4">
          <h1 className="text-2xl font-bold text-primary-500">{title}</h1>
          <p className="text-sm w-full max-w-screen-lg">{subTitle}</p>
        </div>

        <ProjectSlider sliderImages={sliderImages} />

        <div className="flex gap-8 justify-center mb-12">
          <div className="flex items-center gap-2">
            <h3 className="text-xl">Space Size - </h3>
            <p>{spaceSize} sqft</p>
          </div>
          <div className="flex items-center gap-2">
            <h3 className="text-xl">Industry - </h3>
            <p>{industry}</p>
          </div>
          <div className="flex items-center gap-2">
            <h3 className="text-xl">Location - </h3>
            <p>
              {location.city}, {location.state}
            </p>
          </div>
        </div>

        {textSection?.map((item, idx) => (
          <div className="relative">
            {!item.isActive && (
              <p className="absolute top-5 right-5 text-xs text-white bg-red-600 py-1 px-2 rounded-full">
                Section Not Live
              </p>
            )}
            <div key={idx} className="relative">
              <div
                className={classNames("mb-4 flex gap-3 py-10 px-5 items-center rounded-3xl", {
                  "flex-row-reverse bg-primary-500 text-white": idx % 2 === 0,
                })}>
                <div className="p-4 w-2/5">
                  <h2 className="text-2xl mb-3 font-bold">{item.title}</h2>
                  <h3 className="text-lg mb-3 font-semibold">{item.subTitle}</h3>
                  <p>{item.description}</p>
                </div>
                <div className="w-3/5 relative">
                  <img
                    className="rounded-xl w-[80%] h-[400px] object-cover"
                    src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.textSectionImgs[0]}`}
                    alt="Section main"
                  />
                  <div className="w-52 absolute top-1/2 -translate-y-1/2 right-10">
                    {item.textSectionImgs.slice(1).map((img, index) => (
                      <Img
                        key={index}
                        className="rounded-2xl mb-3 h-32 object-cover w-52"
                        src={`${process.env.REACT_APP_BACK_URL}/api/minio/${img}`}
                        alt={`Section ${index + 1}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {virtualLink && (
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-primary-500 text-center mb-3">Virtual Image Section</h2>
            <div className="mb-4 relative rounded-2xl overflow-hidden">
              <iframe src={virtualLink} width="100%" height="500"></iframe>
            </div>
          </div>
        )}

        {youtubeLink && (
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-primary-500 text-center mb-3">Video Section</h2>
            <div className="mb-4 relative rounded-2xl overflow-hidden bg-[#141414]">
              <iframe
                width="100%"
                height={500}
                src={youtubeLink}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </div>
          </div>
        )}

        {beforeAfterImages?.length > 0 && (
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-primary-500 text-center mb-3">Before After Section</h2>
            <div className="mb-4 relative rounded-2xl overflow-hidden">
              <BeforeAfter data={beforeAfterImages} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProjectShowcaseView;
