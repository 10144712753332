import React from "react";
import Layout from "./Layout";
import { Link, useNavigate } from "react-router-dom";

const Trending = () => {
  return (
    <Layout>
      <div className="container mx-auto p-3 lg:px-20">
        <h1 className="text-3xl text-center font-semibold mb-5">Best of Workspace Trendings</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7 my-5">
          <Link to="/trending/reception-waiting_area" className="trends-card cursor-pointer">
            <div className="rounded-2xl w-full overflow-hidden shadow-lg">
              <img className="w-full mb-2" src="https://static.airbrickinfra.com/brick-img/AG/8945.jpg" />
              <div className="grid grid-cols-3 gap-2">
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/V/3682.jpg" />
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/ACD/9589.jpg" />
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/ACN/9730.jpg" />
              </div>
            </div>
            <h1 className="text-xl text-center font-semibold mt-2 mb-5">Reception</h1>
          </Link>

          <Link to="/trending/pantry_area-coffee_point" className="trends-card cursor-pointer">
            <div className="rounded-2xl w-full overflow-hidden shadow-lg">
              <img className="w-full mb-2" src="https://static.airbrickinfra.com/brick-img/AG/8961.jpg" />
              <div className="grid grid-cols-3 gap-2">
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/ACG/9630.jpg" />
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/XB/3807.jpg" />
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/WZ/9030.jpg" />
              </div>
            </div>
            <h1 className="text-xl text-center font-semibold mt-2 mb-5">Pantry</h1>
          </Link>

          <Link to="/trending/large_meeting_room" className="trends-card cursor-pointer">
            <div className="rounded-2xl w-full overflow-hidden shadow-lg">
              <img className="w-full mb-2" src="https://static.airbrickinfra.com/brick-img/CY/1324.jpg" />
              <div className="grid grid-cols-3 gap-2">
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/DK/8419.jpg" />
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/DB/2573.jpg" />
                <img className="w-full h-[80px]" src="https://static.airbrickinfra.com/brick-img/DL/7761.jpg" />
              </div>
            </div>
            <h1 className="text-xl text-center font-semibold mt-2 mb-5">Large Meeting Room</h1>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Trending;
