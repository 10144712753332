import React, { useState } from "react";
import classNames from "classnames";
import ProjectGallery from "./ProjectGallery";
import { Icon } from "@iconify/react";

const ProjectSlider = ({ sliderImages }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {show ? (
        <div className="relative">
          <ProjectGallery sliderImages={sliderImages} />
          <span
            onClick={() => setShow(false)}
            className="absolute top-3 right-3 flex items-center bg-primary-500 text-white text-sm py-2 px-4 rounded-full cursor-pointer">
            <Icon className="text-xl" icon="pajamas:close" />
            Close All
          </span>
        </div>
      ) : (
        <div onClick={() => setShow(true)} className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 mb-5">
          <div className="h-[450px] col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer">
            <div className="absolute inset-0">
              <img
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={`${process.env.REACT_APP_BACK_URL}/api/minio/${sliderImages[0]}`}
                alt="Main project"
              />
            </div>
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {sliderImages.slice(1, 5).map((item, index) => (
            <div
              key={index}
              className={classNames("h-[220px] relative rounded-md sm:rounded-xl overflow-hidden", {
                "hidden sm:block": index >= 2,
              })}>
              <div className="aspect-w-4 aspect-h-3 h-full sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item}`}
                  alt={`Gallery ${index + 1}`}
                />
              </div>
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer" />
            </div>
          ))}

          <span className="absolute bottom-3 right-3 flex items-center gap-1 bg-white py-2 px-4 rounded-full text-sm cursor-pointer">
            <Icon icon="hugeicons:menu-square" />
            View All
          </span>
        </div>
      )}
    </>
  );
};

export default ProjectSlider;
