import React from "react";
import { useParams } from "react-router-dom";
import Img from "../components/Img";

const ImagePreview = () => {
  const { mask, id } = useParams();
  return (
    <div className="flex justify-center items-center h-screen w-full">
      <Img className="max-h-screen" src={`https://static.airbrickinfra.com/brick-img/${mask}/${id}.jpg`} />
    </div>
  );
};

export default ImagePreview;
