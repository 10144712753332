import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Rect, Transformer } from "react-konva";
import * as Unicons from "@iconscout/react-unicons";
import MapperBg from "../../assets/image-mapper-bg.webp";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "./../Layout";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Img from "../../components/Img";
import NoResultsComponent from "../../components/NoResultsComponent";
import debounce from "lodash/debounce";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.mjs";

pdfjsLib.GlobalWorkerOptions.workerSrc = "pdf.worker.mjs";

const ImageMapper = () => {
  const imageRef = useRef(null);
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [image, setImage] = useState({
    name: "",
    file: "",
    src: "",
    rw: 0,
    rh: 0,
  });
  const [projectName, setProjectName] = useState("");
  const [showMapper, setShowMapper] = useState(false);
  const [navigate_FP_ID, setFpId] = useState("");
  const [selfUploadData, setSelfUploadData] = useState([]);
  const [area, setArea] = useState([]);

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  const labelOptions = [
    "boss_cabin",
    "open_office",
    "manager_cabin",
    "work_lounge",
    "pantry_area-coffee_point",
    "reception-waiting_area",
    "restroom",
    "cafeteria",
    "boardroom",
    "small_meeting_room",
    "large_meeting_room",
  ];
  const [selectedLabel, setSelectedLabel] = useState("");
  const [generatedCoordinates, setGeneratedCoordinates] = useState([]);
  const [filterMenu, setfilterMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleFileForm = () => {
    if (image.src && projectName !== "") {
      setShowMapper(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type === "application/pdf") {
      handlePdfUpload(file);
    } else {
      handleImageUpload(file);
    }
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImage({
          name: file.name,
          src: img.src,
          file: file,
        });
      };
    };

    reader.readAsDataURL(file);
  };

  const handlePdfUpload = async (file) => {
    const uri = URL.createObjectURL(file);
    const pdf = await pdfjsLib.getDocument({ url: uri }).promise;

    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1.5 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;
    const imgData = canvas.toDataURL("image/jpeg");

    // Convert data URI to Blob
    const byteCharacters = atob(imgData.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create a new File object with the blob and assign it to the state
    const convertedFile = new File([blob], file.name.replace(".pdf", ".jpg"), { type: "image/jpeg" });
    setImage({
      name: convertedFile.name,
      src: imgData,
      file: convertedFile,
    });
  };

  const handleAddRect = () => {
    if (selectedLabel !== "") {
      const newRect = {
        id: area.length + 1,
        label: selectedLabel,
        coords: [],
      };

      setArea((prevAreas) => [...prevAreas, newRect]);
      setSelectedLabel(""); // Reset to the default value after adding
    }
  };

  const handleRemoveRect = (id) => {
    const updatedAreas = area.filter((rect) => rect.id !== id);
    setArea(updatedAreas);
  };

  const handleGenerateCoordinates = () => {
    const coordinates = area.map((rectObj) => {
      const [x1, y1, x2, y2] = rectObj.coords;
      return {
        id: rectObj.id,
        Tag: rectObj.label,
        coords: [x1, y1, x2, y2],
      };
    });

    setGeneratedCoordinates(coordinates);
  };

  const handleRectClicked = (e, id) => {
    setArea((prevAreas) =>
      prevAreas.map((rectObj) =>
        rectObj.id === id
          ? { ...rectObj, isSelected: true, selectedNode: e.target }
          : { ...rectObj, isSelected: false, selectedNode: null },
      ),
    );
  };

  const handleRectDragEnd = (e, id) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const newAttrs = {
      x: node.x(),
      y: node.y(),
      width: node.width() * scaleX,
      height: node.height() * scaleY,
    };

    const { x, y, width, height } = newAttrs;

    setArea((prevAreas) =>
      prevAreas.map((rectObj) =>
        rectObj.id === id
          ? {
              ...rectObj,
              isSelected: true,
              selectedNode: e.target,
              coords: [x, y, x + width, y + height],
            }
          : { ...rectObj, isSelected: false, selectedNode: null },
      ),
    );
  };

  const saveFloorPlan = async (generatedCoordinates, imageFile) => {
    let loadingToastId; // Declare loadingToastId variable outside try-catch block
    try {
      loadingToastId = toast.loading("Saving FloorPlan data...");
      const formData = new FormData();
      formData.append("floorImage", imageFile);
      formData.append("projectName", projectName);
      formData.append("imgW", image.rw);
      formData.append("imgH", image.rh);
      formData.append("maps", JSON.stringify(generatedCoordinates));

      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/floor/self-floorplan`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });

      if (response.status === 201) {
        const { _id } = response.data;
        setFpId(_id);
        toast.update(loadingToastId, {
          render: "Successfully saved floor plan!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      } else {
        throw new Error("Error saving floor plan.");
      }
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Error saving floor plan.",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      console.error(error);
    }
  };

  const fetchSelfPlans = async () => {
    try {
      setSaveLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/api/floor/self-floorplan?projectName=${searchTerm}`,
        axiosConfig,
      );
      setSelfUploadData(res.data);
    } catch (error) {
      console.error("Error fetching self floor plans:", error);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    const fetchSelfPlansDebounced = debounce(fetchSelfPlans, 1000);
    fetchSelfPlansDebounced();
    return () => {
      fetchSelfPlansDebounced.cancel();
    };
  }, [searchTerm]); // Fetch data on component mount

  useEffect(() => {
    handleGenerateCoordinates();
  }, [area]);

  const handleDeleteFloor = async (id) => {
    try {
      console.log(id);
      const res = await axios.delete(`${process.env.REACT_APP_BACK_URL}/api/floor/self-floorplan/${id}`, axiosConfig);
      if (res.status === 200) {
        toast.success("Floor plan deleted successfully");
        fetchSelfPlans();
      } else toast.error("Somthing went wrong...");
    } catch (error) {
      console.error("Error deleting floor plan:", error);
      toast.error("An error occurred while deleting the floor plan");
    }
  };
  return (
    <Layout>
      <aside
        id="logo-sidebar"
        onClick={() => setfilterMenu(false)}
        className={`fixed top-0 left-0 z-50 w-full h-screen transition-transform ${
          filterMenu ? "translate-x-0" : "-translate-x-full"
        } bg-[rgba(0,0,0,0.7)] border-r border-gray-200 `}
        aria-label="Sidebar"
      >
        <div onClick={(e) => e.stopPropagation()} className="w-1/2 bg-white">
          <div className="flex justify-between p-3 items-center bg-gray-50 border-b">
            <h2 className="text-xl font-bold">Saved Floorplans</h2>
            <div onClick={() => setfilterMenu(false)} className="bg-[#efefef] p-2 rounded-full cursor-pointer">
              <Unicons.UilTimes />
            </div>
          </div>
          <div className="bg-slate-100 p-5">
            <div className="flex border rounded-lg bg-white overflow-hidden items-center">
              <input
                className="py-2 px-3 border-none outline-none flex-grow"
                placeholder="Search By Name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Unicons.UilSearch className="opacity-60 mx-2" />
            </div>
          </div>

          <div className="h-[100vh] px-3 pt-3 pb-40 overflow-y-auto">
            {!saveLoading ? (
              selfUploadData.length > 0 ? (
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 950: 3, 1100: 4 }}>
                  <Masonry gutter={"1rem"}>
                    {selfUploadData.map((item) => (
                      <div
                        key={item._id}
                        className="relative cursor-pointer rounded-lg overflow-hidden border"
                        onClick={() => navigate(item._id)}
                      >
                        <Img
                          className="w-full"
                          src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.imageUrl}`}
                          alt={`Self Upload Plan - ${item._id}`}
                        />
                        <p className="px-2 py-1 text-center bg-primary-500 text-white text-sm text-ellipsis overflow-hidden whitespace-nowrap">
                          {item.projectName ?? "No Name"}
                        </p>
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event propagation
                            handleDeleteFloor(item._id);
                          }}
                          className="bg-red-500 text-white p-1 rounded-full absolute top-2 right-2 z-10"
                        >
                          <Unicons.UilTrashAlt />
                        </button>
                      </div>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              ) : (
                <NoResultsComponent />
              )
            ) : (
              <div className="flex items-center justify-center bg-white w-full h-full">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-primary-500"></div>
              </div>
            )}
          </div>
        </div>
      </aside>

      <div className="w-full max-w-screen-xl mx-auto min-h-[calc(100vh-7rem)] bg-white flex items-center">
        {showMapper ? (
          <div className="w-full max-w-screen-xl mx-auto mt-8">
            <div className="border-2 p-3 rounded-lg mx-5">
              <form className="flex gap-3 items-center">
                <select
                  className="w-full border rounded p-3"
                  value={selectedLabel}
                  onChange={(e) => setSelectedLabel(e.target.value)}
                >
                  <option disabled value="">
                    Choose a label from here....
                  </option>
                  {labelOptions.map((label) => {
                    const formattedLabel = label
                      .replace(/_/g, " ")
                      .replace(/-/g, " & ")
                      .split(" ")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ");
                    return (
                      <option key={label} value={label}>
                        {formattedLabel}
                      </option>
                    );
                  })}
                </select>
                <button
                  disabled={selectedLabel === ""}
                  className="bg-green-500 text-white p-3 rounded w-28 disabled:bg-gray-400"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddRect();
                  }}
                >
                  Add Rect
                </button>
              </form>
            </div>
            {area.length > 0 && (
              <div className="p-3 bg-slate-100 mt-10 mx-5 border-2 rounded flex flex-wrap gap-3 justify-center">
                {area.map((item) => {
                  const itemLabel = item.label
                    .replace(/_/g, " ")
                    .replace(/-/g, " & ")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                  return (
                    <div
                      key={item.id}
                      className={`bg-white border-2 p-1 rounded-full flex gap-3 items-center ${
                        item.isSelected && "border-teal-500"
                      }`}
                    >
                      <p className="px-3">{itemLabel}</p>
                      <button
                        className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
                        onClick={() => {
                          handleRemoveRect(item.id);
                        }}
                      >
                        <Unicons.UilTimes size="20" />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="p-5 border-2 rounded-md flex justify-center mt-10 mx-5 bg-white">
              <div className="relative">
                <img
                  ref={imageRef} // Correct placement of imageRef
                  src={image.src}
                  alt="some"
                  className="w-full max-w-[1000px] mx-auto"
                  onLoad={() => {
                    if (imageRef.current) {
                      setImage((prev) => ({
                        ...prev,
                        rw: imageRef.current.clientWidth,
                        rh: imageRef.current.clientHeight,
                      }));
                    }
                  }}
                />

                <Stage width={image.rw} height={image.rh} className="absolute top-0 left-0 z-10 bg-[rgba(0,0,0,0.4)]">
                  <Layer>
                    {area.map((rectObj) => (
                      <React.Fragment key={rectObj.id}>
                        <Rect
                          x={10}
                          y={10}
                          width={100}
                          height={100}
                          fill={rectObj?.isSelected ? "green" : "black"}
                          opacity={0.5}
                          onClick={(e) => handleRectClicked(e, rectObj.id)}
                          onDragEnd={(e) => handleRectDragEnd(e, rectObj.id)}
                          draggable={rectObj?.isSelected || false}
                        />
                        {rectObj.selectedNode && (
                          <Transformer
                            anchorSize={8}
                            borderDash={[6, 2]}
                            rotateEnabled={false}
                            onTransformEnd={(e) => handleRectDragEnd(e, rectObj.id)}
                            ref={(node) => {
                              if (node && rectObj?.isSelected) {
                                node.nodes([rectObj?.selectedNode]);
                              }
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Layer>
                </Stage>
              </div>
            </div>
            <div className="m-5 justify-center flex gap-10">
              {navigate_FP_ID === "" ? (
                <button
                  className="py-2.5 px-3 bg-green-500 text-white rounded"
                  onClick={() => {
                    saveFloorPlan(generatedCoordinates, image.file);
                  }}
                >
                  Save FloorPlan
                </button>
              ) : (
                <button
                  className="py-2.5 px-3 bg-green-500 text-white rounded"
                  onClick={() => navigate(`/self-moodboard/floorId/${navigate_FP_ID}`)}
                >
                  Go to Moodboard
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full max-w-screen-xl mx-auto flex items-center justify-center">
            <div className="w-3/5">
              <img className="w-full h-full object-cover" src={MapperBg} />
            </div>
            <div className="bg-white w-2/5 h-full">
              <div className="flex flex-col p-6 space-y-1">
                <h3 className="font-semibold tracking-tight text-2xl">Upload a FloorPlan</h3>
                <p className="text-sm text-muted-foreground">
                  Add your file (.jpg Allowed) below to create your project.
                </p>
              </div>
              <div className="p-6 pt-0 grid gap-4">
                <button
                  onClick={() => setfilterMenu(!filterMenu)}
                  className="inline-flex gap-1 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2"
                >
                  <Unicons.UilFileSearchAlt size="20" />
                  Saved Floorplans
                </button>

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-white px-2 text-muted-foreground">Or continue with</span>
                  </div>
                </div>
                <div className="grid gap-2">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Project Name
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white focus:outline-none"
                    placeholder="Enter your project name..."
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="grid gap-2">
                  <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    htmlFor="email"
                  >
                    Choose file
                  </label>
                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg, .pdf"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white focus:outline-none"
                  />
                </div>

                <button
                  onClick={handleFileForm}
                  disabled={!(image.src && projectName !== "")}
                  className="py-2 px-2 mt-3 text-sm rounded-lg bg-teal-500 w-full text-white font-semibold disabled:bg-gray-300"
                >
                  Upload Floorplan
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ImageMapper;
