import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react"; // Assuming you are using @iconify/react for icons
import ImgDrop from "../../../assets/na7p8lprxplx309m10.png";
import Layout from "../../Layout";
import axios from "axios"; // If you choose to use axios
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ImageUploadEdit = () => {
  const [formData, setFormData] = useState({ title: "", projectImages: [] });
  const [errors, setErrors] = useState({ title: null, projectImages: null });
  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/project-showcases/${id}`);
        setFormData((prev) => ({
          ...prev,
          title: response.data.title,
        }));
      } catch (error) {
        console.error("Failed to fetch project data", error);
      }
    };

    fetchProject();
  }, [id]);
  const handleDropImages = useCallback((acceptedFiles) => {
    setFormData((prev) => ({
      ...prev,
      projectImages: [...prev.projectImages, ...acceptedFiles],
    }));
  }, []);

  const removeImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      projectImages: prev.projectImages.filter((_, i) => i !== index),
    }));
  };

  const handleTitleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      title: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    if (formData.title.trim() === "") {
      setErrors((prev) => ({ ...prev, title: "Please enter a project title." }));
      return;
    }

    if (formData.projectImages.length === 0) {
      setErrors((prev) => ({ ...prev, projectImages: "Please upload at least one image." }));
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formData.projectImages.forEach((file, index) => {
      formDataToSend.append(`projectImages_${index}`, file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/project-showcases/re-upload/${id}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("usersdatatoken"),
          },
        },
      );
      // Handle success response
      toast.success("Images uploaded successfully");
      setFormData((prev) => ({ ...prev, projectImages: [] }));
    } catch (error) {
      // Handle error response
      console.error("Error uploading images:", error);
      toast.error("Somthing went wrong...");
    }
  };

  const { getRootProps: getRootPropsImages, getInputProps: getInputPropsImages } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: handleDropImages,
  });

  return (
    <Layout>
      <div className="p-3 mx-auto container bg-slate-50">
        <h1 className="text-2xl mt-3 mb-8">Re-Upload Project Images</h1>
        <div className="flex flex-col px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
          <div>
            <input
              className="py-2 px-3 border w-full rounded-lg outline-primary-500"
              value={formData.title}
              readOnly
              disabled
              onChange={handleTitleChange}
              placeholder="Enter project title..."
            />
            {errors.title && <p className="text-red-500 text-xs px-2 pt-1">{errors.title}</p>}
          </div>
          <div {...getRootPropsImages()} className="border w-full rounded-lg p-4 cursor-pointer mt-4">
            <input {...getInputPropsImages()} accept="image/*" />
            <p>
              Drop files here or click <span className="text-primary-500">browse</span> through your machine
            </p>
          </div>
          {errors.projectImages && <p className="text-red-500 text-xs px-2 pt-1">{errors.projectImages}</p>}
          <div className="mt-4 border w-full rounded-lg p-4 h-80 overflow-y-auto">
            {formData.projectImages.map(
              (file, index) =>
                file.size > 3 * 1024 * 1024 && (
                  <div key={index} className="bg-red-50 text-xs border border-red-300 rounded-lg p-3 mb-2">
                    <p className="font-semibold">{file.path}</p>
                    <p className="">- File is larger than 3MB</p>
                  </div>
                ),
            )}
            <div className="grid grid-cols-10 gap-2">
              {formData.projectImages.map((file, index) => (
                <div key={index} className="relative h-20">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Image ${index}`}
                    className="w-full h-20 object-cover rounded"
                  />
                  {file.size > 3 * 1024 * 1024 && (
                    <Icon
                      icon="fluent-emoji-flat:warning"
                      className="absolute text-2xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    />
                  )}
                  <Icon
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full size-5 text-center p-1 cursor-pointer"
                    icon="mdi:trash-can-outline"
                  />
                </div>
              ))}
            </div>
            {formData.projectImages.length === 0 && (
              <div className="w-full h-full grid place-content-center">
                <img className="w-[200px]" src={ImgDrop} alt="Drop Area" />
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-3 justify-end items-center">
          <button
            type="button" // Changed to type="button" to avoid form submission if inside a form element
            className="bg-green-500 py-2 px-3 rounded-lg text-white"
            onClick={handleSubmit}
          >
            Upload Images
          </button>
          <Link to={`/project-showcase/${id}/add`}>
            <button
              type="button" // Changed to type="button" to avoid form submission if inside a form element
              className="bg-red-500 py-2 px-3 rounded-lg text-white"
              onClick={handleSubmit}
            >
              Done
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default ImageUploadEdit;
