import "./style.css";
import { useState, useEffect } from "react"; // Import useEffect
import InfiniteScroll from "react-infinite-scroll-component";
import Img from "../components/Img";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import NoResultsComponent from "./../components/NoResultsComponent";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const token = localStorage.getItem("usersdatatoken");
const axiosConfig = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: token,
  },
};

export default function TrendingCategory() {
  const { category } = useParams();
  const [images, setImages] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/api/image/trending/${category}`, axiosConfig);
      const data = await response.json();

      // Check if data is an array before updating state
      if (Array.isArray(data)) {
        if (data.length === 0) {
          setHasMore(false);
        } else {
          setImages((prevImages) => [...prevImages, ...data]);
        }
      } else {
        console.error("Invalid data format:", data);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout className="App">
      <h2 className="text-center text-xl sm:text-2xl font-bold py-5">
        Trending images from <span className="text-primary-500 underline">{category}</span> category
      </h2>
      <div className="w-full p-3">
        {images?.length > 0 ? (
          <InfiniteScroll
            dataLength={images?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<p className="w-full text-center">Loading ...</p>}
            endMessage={<p className="w-full text-center">Yay! You have seen it all</p>}
          >
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1100: 3, 1500: 4 }}>
              <Masonry gutter="1rem">
                {images?.map((image, index) => (
                  <Img
                    key={index}
                    src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image?.ID}.jpg`}
                    className="rounded-xl shadow"
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        ) : (
          <NoResultsComponent />
        )}
      </div>
    </Layout>
  );
}
