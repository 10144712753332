import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "./Layout";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import AiLoader from "../components/AiLoader";
import BookMarkIcon from "../assets/BookMark.png";
import WaterMark from "../assets/logo-full.png";

const Project = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mask = queryParams.get("mask") || "";

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const token = localStorage.getItem("usersdatatoken");
  const headers = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  const [selectedImage, setSelectedImage] = useState({ Mask: "", ID: "" });
  const [projectUrl, setProjectUrl] = useState({ Mask: "", ID: "" });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
    setIsLoading(true);
  }, [mask]);

  useEffect(() => {
    setProjectUrl({ Mask: selectedImage?.Mask, ID: selectedImage?.ID });
  }, [selectedImage]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/image/project?mask=${mask}`, headers);
      setImages(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const copyUrl = async () => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}/project?mask=${mask}`);
    toast.success("Project Url copied...");
  };

  // popup code goes here all //

  const openImagePopup = (Mask, ID) => {
    setSelectedImage({ Mask: Mask, ID: ID });
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  const CustomPrevArrow = (props) => (
    <div className="slick-arrow custom-prev-arrow" onClick={props.onClick}>
      <span>
        <Unicons.UilAngleLeft />
      </span>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="slick-arrow custom-next-arrow" onClick={props.onClick}>
      <span>
        <Unicons.UilAngleRight />
      </span>
    </div>
  );

  const initialSlideIndex = selectedImage
    ? images.findIndex((image) => image?.Mask === selectedImage.Mask && image.ID === selectedImage.ID)
    : 0;

  const downloadImage = async () => {
    try {
      const fileFolder = projectUrl?.Mask;
      const fileName = projectUrl?.ID;

      if (!fileFolder || !fileName) {
        console.error("File folder or file name is missing");
        return;
      }

      const fullPath = `${fileFolder}/${fileName}.jpg`;
      const imgUrl = `https://static.airbrickinfra.com/brick-img/${fullPath}`;

      var id = toast.loading("Please wait...");

      const response = await axios.get(imgUrl, {
        responseType: "arraybuffer", // Ensure response is treated as an array buffer
      });

      const blob = new Blob([response.data]); // Create a blob from the array buffer
      const url = window.URL.createObjectURL(blob); // Create a URL for the blob

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullPath);
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.update(id, { render: "Downloading...", type: "success", isLoading: false, autoClose: 2000 });
    } catch (error) {
      console.error("An error occurred:", error);
      toast.update(id, { render: "Sorry, An error occurred", type: "error", isLoading: false, autoClose: 2000 });
    }
  };

  const copyImageUrl = async () => {
    let id;
    try {
      id = toast.loading("Please wait...");
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_FRONT_URL}/imageview/${projectUrl?.Mask}/${projectUrl?.ID}`,
      );
      toast.update(id, { render: "Link Copied..", type: "success", isLoading: false, autoClose: 2000 });
    } catch (error) {
      toast.update(id, { render: "An error occurred", type: "error", isLoading: false, autoClose: 2000 });
    }
  };

  return (
    <Layout>
      <div>
        <AiLoader />
        <div className="flex justify-between px-3 container mx-auto">
          <button
            onClick={() => navigate(-1)}
            className="py-1 px-3 pr-6 rounded-full bg-primary-500 text-xs flex text-white items-center"
          >
            <span>
              <Unicons.UilArrowLeft />
            </span>
            Back
          </button>

          <h2>Showing results from project : {mask}</h2>
          <button
            onClick={copyUrl}
            className="ml-3 py-1 px-3 pr-6 rounded-full bg-primary-500 text-xs flex text-white items-center"
          >
            <span>
              <Unicons.UilLink />
            </span>
            Copy Url
          </button>
        </div>

        <div className="container showcase-container mx-auto p-2 md:p-4">
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => openImagePopup(image?.Mask, image.ID)}
              className="relative item bg-white overflow-hidden rounded-3xl shadow-md hover:scale-[1.07] hover:shadow-2xl transition duration-300 cursor-pointer"
            >
              <img
                src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image.ID}.jpg`}
                alt="Image"
                className="object-cover rounded-3xl w-full h-full"
              />
              {image?.Trending === 1 && (
                <div className="absolute top-0 right-3 z-10">
                  <img className="w-8" src={BookMarkIcon} alt="Bookmarks" />
                </div>
              )}
            </div>
          ))}

          {selectedImage?.Mask && (
            <div className="fixed inset-0 z-50 flex items-center justify-center pop-backdrop" onClick={closeImagePopup}>
              <div className="fixed inset-0 overflow-auto">
                <div className="flex items-center justify-center min-h-screen">
                  <div
                    style={{
                      background: `url('/p-bg2.webp') no-repeat center`,
                      backgroundSize: "cover",
                    }}
                    className="relative w-full max-w-screen-lg max-h-screen mx-auto rounded-xl shadow"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* Modal header */}
                    <div className="flex items-start justify-between rounded-t ">
                      <button
                        onClick={closeImagePopup}
                        type="button"
                        className="absolute top-3 right-3 sm:top-5 sm:right-5 z-50 bg-[#efefef] hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                      >
                        <Unicons.UilTimes />
                      </button>
                    </div>
                    {/* Modal body */}
                    <div className="p-3 pb-0">
                      <div className="slider-container ">
                        <Slider
                          arrows
                          infinite
                          asNavFor={nav2}
                          ref={(slider1) => setNav1(slider1)}
                          speed={500}
                          prevArrow={<CustomPrevArrow />}
                          nextArrow={<CustomNextArrow />}
                          initialSlide={initialSlideIndex}
                          afterChange={(currentSlide) => {
                            const currentImage = images[currentSlide];
                            if (currentImage?.Mask) {
                              setProjectUrl({ Mask: currentImage?.Mask, ID: currentImage?.ID });
                            }
                          }}
                        >
                          {images.map((image, index) => (
                            <div key={index}>
                              <div className="slider-cont p-0 h-[60vh] md:h-[70vh] flex items-center overflow-hidden">
                                <div className="relative mx-auto h-auto md:h-[70vh] rounded-2xl">
                                  <img
                                    src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image.ID}.jpg`}
                                    className="mx-auto h-auto md:h-[70vh] rounded-2xl"
                                    alt={`Project ${index}`}
                                  />
                                  <img className="w-20 absolute bottom-3 left-3" src={WaterMark} />
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>

                        <Slider
                          asNavFor={nav1}
                          ref={(slider2) => setNav2(slider2)}
                          arrows={false}
                          slidesToShow={4}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          initialSlide={initialSlideIndex}
                          className="lg:px-32"
                        >
                          {images.map((image, index) => (
                            <div key={index} className="px-1">
                              <img
                                src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image.ID}.jpg`}
                                className="h-[70px] w-full object-cover rounded-lg"
                                alt={`Project ${index}`}
                              />
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex items-center justify-center md:justify-between p-3 rounded-b-2xl flex-wrap bg-slate-100 gap-4">
                      <div className="flex gap-4">
                        <button
                          type="button"
                          className="bg-white border-2 border-gray-400 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                        >
                          {projectUrl?.Mask}
                        </button>
                        <button
                          type="button"
                          className="bg-white border-2 border-gray-400 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                        >
                          ID: {projectUrl?.ID}
                        </button>
                      </div>

                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => toast.success("Success, Added to Moodboard")}
                          type="button"
                          className="bg-gray-300 py-2 px-3 rounded-lg hover:bg-green-400 gap-2 flex items-center justify-center cursor-pointer"
                        >
                          <Unicons.UilHeart />
                        </button>
                        <button
                          onClick={copyImageUrl}
                          type="button"
                          className="bg-gray-300 py-2 px-3 rounded-lg hover:bg-green-400 gap-2 flex items-center justify-center cursor-pointer"
                        >
                          <Unicons.UilLink />
                        </button>

                        <button
                          onClick={downloadImage}
                          type="button"
                          className="bg-gray-300 py-2 px-3 rounded-lg hover:bg-green-400 gap-2 flex items-center justify-center cursor-pointer"
                        >
                          <Unicons.UilImageDownload />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Project;
