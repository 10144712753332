import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./style.css";
import Loader from "../components/Loader";
import NoResultsComponent from "../components/NoResultsComponent";
import * as Unicons from "@iconscout/react-unicons";
import Layout from "./Layout";
import Imgplc2 from "../assets/placeholder-600x400.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import filterIcon from "../assets/filter-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import Img from "./../components/Img";
import BookMarkIcon from "../assets/BookMark.png";
import WaterMark from "../assets/logo-full.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ShowCase = () => {
  const specificTags = [
    "open_office",
    "boss_cabin",
    "manager_cabin",
    "work_lounge",
    "boardroom",
    "breakout_space",
    "large_meeting_room",
    "small_meeting_room",
    "pantry_area-coffee_point",
    "reception-waiting_area",
  ];

  const [images, setImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [filterMenu, setfilterMenu] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [selectedImage, setSelectedImage] = useState({ Mask: "", ID: "" });
  const [projectUrl, setProjectUrl] = useState({ Mask: "", ID: "" });
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  const params = new URLSearchParams(location.search);
  const getParams = params.get("tags");

  useEffect(() => {
    if (getParams) {
      const newTags = getParams.replace(/^all\./, "");
      setSelectedTags(newTags.split("."));
    } else {
      setSelectedTags(["all"]);
    }
  }, [getParams]);

  useEffect(() => {
    if (selectedTags.length > 0) {
      fetchImagesAndTags();
    }
  }, [selectedTags, currentPage]);

  const fetchImagesAndTags = async () => {
    setIsLoading(true);
    try {
      const [imagesResponse, tagsResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_BACK_URL}/api/image/filter?tags=${selectedTags}&limit=50&page=${currentPage}`,
          axiosConfig,
        ), // Join selectedTags to create a single string
        axios.get(`${process.env.REACT_APP_BACK_URL}/api/tag/get`, axiosConfig),
      ]);

      setImages(
        imagesResponse.data.images.filter(
          (image) =>
            image.Mask !== "Sofa" &&
            image.Mask !== "Chairs" &&
            image.Mask !== "Lounge" &&
            image.Mask !== "Pouffes" &&
            image.Mask !== "Glass" &&
            image.Mask !== "Lights",
        ),
      );
      setTotalPages(imagesResponse.data.totalPages);
      setTags(tagsResponse.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleTagChange = (event) => {
    const tagName = event.target.value;
    const isChecked = event.target.checked;

    let updatedTags = [];

    if (isChecked) {
      updatedTags = [...selectedTags, tagName];
    } else {
      updatedTags = selectedTags.filter((tag) => tag !== tagName);
    }

    setSelectedTags(updatedTags);

    const queryParams = new URLSearchParams();
    if (updatedTags.length > 0) {
      const newTags = updatedTags.filter((tag) => tag !== "all");
      queryParams.set("tags", newTags.join("."));
    }
    navigate({ search: queryParams.toString() });
    setCurrentPage(1);
  };

  useEffect(() => {
    setProjectUrl({ Mask: selectedImage?.Mask, ID: selectedImage?.ID });
  }, [selectedImage]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1); // Pages are 0-based, but currentPage starts from 1
  };

  const toggleCategory = (event) => {
    const dropdown = event.currentTarget.closest(".category-dropdown");
    dropdown.classList.toggle("active");
  };

  const CustomBtnPrevArrow = (props) => (
    <div className="slick-btn-arrow custom-btn-prev-arrow" onClick={props.onClick}>
      <span>
        <Unicons.UilAngleLeft />
      </span>
    </div>
  );

  const CustomBtnNextArrow = (props) => (
    <div className="slick-btn-arrow custom-btn-next-arrow" onClick={props.onClick}>
      <span>
        <Unicons.UilAngleRight />
      </span>
    </div>
  );

  const renderTagCategory = (category, title) => (
    <div className="categorey">
      <div className="category-title" onClick={toggleCategory}>
        <h2 className="font-semibold">{title}</h2>
        <span>
          <Unicons.UilAngleDown />
        </span>
      </div>
      <div className="category-content">
        <ul className="font-medium space-y-2">
          {tags[category]
            ?.sort() // Sort the tags alphabetically
            .map((tag, index) => (
              <li key={index} className="flex items-center">
                <input
                  id={`tag-${tag}`}
                  type="checkbox"
                  value={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={handleTagChange}
                  className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-500 focus:ring-primary-500 dark:focus:ring-primary-500 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  htmlFor={`tag-${tag}`}
                  className="cursor-pointer w-full ml-2 text-sm font-sm text-gray-900 dark:text-gray-100"
                >
                  {tag.replace(/_/g, " ").replace(/-/g, "/")}
                </label>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );

  // popup code goes here all //

  const openImagePopup = (Mask, ID) => {
    setSelectedImage({ Mask: Mask, ID: ID });
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  const CustomPrevArrow = (props) => (
    <div className="slick-arrow custom-prev-arrow" onClick={props.onClick}>
      <span>
        <Unicons.UilAngleLeft />
      </span>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="slick-arrow custom-next-arrow" onClick={props.onClick}>
      <span>
        <Unicons.UilAngleRight />
      </span>
    </div>
  );

  const initialSlideIndex = selectedImage
    ? images.findIndex((image) => image?.Mask === selectedImage.Mask && image.ID === selectedImage.ID)
    : 0;

  const downloadImage = async () => {
    try {
      const fileFolder = projectUrl?.Mask;
      const fileName = projectUrl?.ID;

      if (!fileFolder || !fileName) {
        console.error("File folder or file name is missing");
        return;
      }

      const fullPath = `${fileFolder}/${fileName}.jpg`;
      const imgUrl = `https://static.airbrickinfra.com/brick-img/${fullPath}`;

      var id = toast.loading("Please wait...");

      const response = await axios.get(imgUrl, {
        responseType: "arraybuffer", // Ensure response is treated as an array buffer
      });

      const blob = new Blob([response.data]); // Create a blob from the array buffer
      const url = window.URL.createObjectURL(blob); // Create a URL for the blob

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullPath);
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.update(id, { render: "Downloading...", type: "success", isLoading: false, autoClose: 2000 });
    } catch (error) {
      console.error("An error occurred:", error);
      toast.update(id, { render: "Sorry, An error occurred", type: "error", isLoading: false, autoClose: 2000 });
    }
  };

  const copyImageUrl = async () => {
    let id;
    try {
      id = toast.loading("Please wait...");
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_FRONT_URL}/imageview/${projectUrl?.Mask}/${projectUrl?.ID}`,
      );
      toast.update(id, { render: "Link Copied..", type: "success", isLoading: false, autoClose: 2000 });
    } catch (error) {
      toast.update(id, { render: "An error occurred", type: "error", isLoading: false, autoClose: 2000 });
    }
  };

  return (
    <Layout>
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-50 w-64 h-screen pt-5 pb-14 transition-transform ${
          filterMenu ? "translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-200 `}
        aria-label="Sidebar"
      >
        <div className="flex justify-between px-3 items-center mb-5">
          <h2 className="text-2xl font-bold">Filter</h2>
          <div onClick={() => setfilterMenu(false)} className="bg-[#efefef] p-2 rounded-full">
            <Unicons.UilTimes />
          </div>
        </div>

        <div className="h-full px-3 py-4 overflow-y-auto">
          <div className="category-dropdown active">{renderTagCategory("Work Areas", "Work Areas")}</div>
          <div className="category-dropdown">{renderTagCategory("Meeting Spaces", "Meeting Spaces")}</div>
          <div className="category-dropdown">{renderTagCategory("Support Spaces", "Support Spaces")}</div>

          <div className="category-dropdown">{renderTagCategory("Features", "Features")}</div>
          <div className="category-dropdown">{renderTagCategory("Flooring", "Flooring")}</div>
          <div className="category-dropdown">{renderTagCategory("Lighting", "Lighting")}</div>

          <div className="category-dropdown">{renderTagCategory("Materials", "Materials")}</div>
          <div className="category-dropdown">
            {renderTagCategory("Environmental Graphics", "Environmental Graphics")}
          </div>
          <div className="category-dropdown">{renderTagCategory("Seating & Tables", "Seating & Tables")}</div>
        </div>
      </aside>

      <div className="results-box container mx-auto">
        <div className="px-2 md:px-4 w-full max-w-screen">
          <div className="mb-3 flex justify-end">
            <span
              onClick={() => setfilterMenu(!filterMenu)}
              className="text-sm w-10 h-10 bg-[#efefef] rounded-full flex justify-center cursor-pointer"
            >
              <img width="16px" src={filterIcon} />
            </span>
          </div>

          <div className="w-full max-w-[80vw] md:max-w-[90vw] mx-auto">
            <Slider
              className="cate-slide"
              infinite
              speed={500}
              arrows={true}
              prevArrow={<CustomBtnPrevArrow />} // Custom previous arrow
              nextArrow={<CustomBtnNextArrow />}
              slidesToShow={4}
              slidesToScroll={1}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                  },
                },
              ]}
            >
              {specificTags.map((tag, index) => (
                <Link
                  key={index}
                  to={`/showcase?tags=${tag}`}
                  className={`${
                    getParams?.includes(tag) ? "active" : ""
                  } inline-flex items-center p-3 mr-3 text-xs font-semibold button-50`}
                >
                  {tag
                    .replace(/_/g, " ")
                    .replace(/-/g, "/")
                    .replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.slice(1))}
                </Link>
              ))}
            </Slider>
          </div>
        </div>

        <div>
          {isLoading ? (
            <Loader />
          ) : images.length > 0 ? (
            <div className="p-4">
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 950: 3, 1100: 4 }}>
                <Masonry gutter={"1.2rem"}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      onClick={() => openImagePopup(image?.Mask, image.ID)}
                      className="hover:scale-[1.05] transform transition duration-500 relative"
                    >
                      <Img
                        className="relative item rounded-xl cursor-pointer w-full h-full min-h-[200px]"
                        src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image.ID}.jpg`}
                        alt="Image"
                      />
                      {image?.Trending === 1 && (
                        <div className="absolute top-0 right-3 z-10">
                          <img className="w-8" src={BookMarkIcon} alt="Bookmarks" />
                        </div>
                      )}
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>

              {selectedImage?.Mask && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center pop-backdrop"
                  onClick={closeImagePopup}
                >
                  <div className="fixed inset-0 overflow-auto">
                    <div className="flex items-center justify-center min-h-screen">
                      <div
                        style={{
                          background: `url('/p-bg2.webp') no-repeat center`,
                          backgroundSize: "cover",
                        }}
                        className="relative w-full max-w-screen-lg max-h-screen mx-auto rounded-xl shadow"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* Modal header */}
                        <div className="flex items-start justify-between rounded-t ">
                          <button
                            onClick={closeImagePopup}
                            type="button"
                            className="absolute top-3 right-3 sm:top-5 sm:right-5 z-50 bg-[#efefef] hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                          >
                            <Unicons.UilTimes />
                          </button>
                        </div>
                        {/* Modal body */}
                        <div className="p-3 pb-0">
                          <div className="slider-container">
                            <Slider
                              arrows
                              infinite
                              asNavFor={nav2}
                              ref={(slider1) => setNav1(slider1)}
                              speed={500}
                              prevArrow={<CustomPrevArrow />}
                              nextArrow={<CustomNextArrow />}
                              initialSlide={initialSlideIndex}
                              afterChange={(currentSlide) => {
                                const currentImage = images[currentSlide];
                                if (currentImage?.Mask) {
                                  setProjectUrl({ Mask: currentImage?.Mask, ID: currentImage?.ID });
                                }
                              }}
                            >
                              {images.map((image, index) => (
                                <div key={index}>
                                  <div className="slider-cont p-0 h-[60vh] md:h-[70vh] flex items-center overflow-hidden">
                                    <div className="relative mx-auto h-auto md:h-[70vh] rounded-2xl">
                                      <img
                                        src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image.ID}.jpg`}
                                        className="mx-auto h-auto md:h-[70vh] rounded-2xl"
                                        onError={(e) => {
                                          e.target.onerror = null; // Prevent infinite loop in case of repeated error
                                          e.target.src = Imgplc2; // Set placeholder image when main image fails to load
                                        }}
                                        alt={`Project ${index}`}
                                      />
                                      <img className="w-20 absolute bottom-3 left-3" src={WaterMark} />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>

                            <Slider
                              asNavFor={nav1}
                              ref={(slider2) => setNav2(slider2)}
                              arrows={false}
                              slidesToShow={5}
                              swipeToSlide={true}
                              focusOnSelect={true}
                              initialSlide={initialSlideIndex}
                              className="lg:px-32"
                            >
                              {images.map((image, index) => (
                                <div key={index} className="px-1">
                                  <img
                                    src={`https://static.airbrickinfra.com/brick-img/${image?.Mask}/${image.ID}.jpg`}
                                    className="h-[70px] w-full object-cover rounded-lg"
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop in case of repeated error
                                      e.target.src = Imgplc2; // Set placeholder image when main image fails to load
                                    }}
                                    alt={`Project ${index}`}
                                  />
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </div>
                        {/* Modal footer */}
                        <div className="flex items-center justify-center md:justify-between p-3 rounded-b-2xl flex-wrap bg-slate-100 gap-4">
                          <div className="flex gap-4">
                            <button
                              type="button"
                              className="bg-white border border-gray-200 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                            >
                              {projectUrl?.Mask}
                            </button>
                            <button
                              type="button"
                              className="bg-white border border-gray-200 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                            >
                              ID: {projectUrl?.ID}
                            </button>
                            <button
                              onClick={() => toast.success("Success, Added to Moodboard")}
                              type="button"
                              className="bg-white border border-gray-200 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                            >
                              <Unicons.UilHeart />
                            </button>

                            <button
                              onClick={copyImageUrl}
                              type="button"
                              className="bg-white border border-gray-200 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                            >
                              <Unicons.UilLink />
                            </button>

                            <button
                              onClick={downloadImage}
                              type="button"
                              className="bg-white border border-gray-200 py-1.5 px-3 rounded-lg gap-2 flex items-center justify-center cursor-pointer"
                            >
                              <Unicons.UilImageDownload />
                            </button>
                          </div>
                          <button
                            onClick={() => window.open(`/project?mask=${projectUrl?.Mask}`, "_blank")}
                            type="button"
                            disabled={
                              projectUrl?.Mask === "BOARDROOM" ||
                              projectUrl?.Mask === "CAFE" ||
                              projectUrl?.Mask === "REST"
                            }
                            className="text-white bg-green-500 hover:bg-green-600 py-2 px-4 rounded-lg disabled:bg-slate-400 disabled:cursor-not-allowed"
                          >
                            Replicate Theme To All Areas
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <NoResultsComponent />
          )}
        </div>

        <div className="flex justify-center m-5">
          {totalPages !== 0 && (
            <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              previousLabel={<Unicons.UilAngleLeftB />}
              nextLabel={<Unicons.UilAngleRightB />}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ShowCase;
