import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const HomeLayout = ({ children }) => {
  return (
    <div>
      <Header position={"absolute backdrop-blur-sm"} bg={"bg-[rgba(255,255,255,0.4)]"} />
      <main className="bg-white">{children}</main>
      <Footer />
    </div>
  );
};

export default HomeLayout;
