import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo-full.png";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import axios from "axios";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import { industries } from "./admin/validationSchema";
import MultiRangeSlider from "./../../components/multiRangeSlider";
import debounce from "lodash/debounce";

const ProjectListing = () => {
  const [projectData, setProjectData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterMenu, setFilterMenu] = useState(false);
  const [filters, setFilters] = useState({
    industry: "",
    spaceSize: [0, 50000],
    search: "",
  });

  const fetchProjects = async (page = 1, filters = {}) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/project-showcases`, {
        params: { page, limit: 20, ...filters },
      });
      const newProjects = response.data.results.filter((item) => item.isLive === true);
      setProjectData((prevProjects) => (page === 1 ? newProjects : [...prevProjects, ...newProjects]));
      setCurrentPage(response.data.currentPage);
      if (currentPage >= response.data.totalPages) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching projects:", error.response ? error.response.data : error);
    }
  };

  useEffect(() => {
    fetchProjects(1, filters);
  }, []);

  const fetchMoreProjects = () => {
    fetchProjects(currentPage + 1, filters);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearchChange = (e) => {
    // Update the search filter state
    setFilters({ ...filters, search: e.target.value });

    // Define a debounced fetch function
    const fetchSelfPlansDebounced = debounce(() => {
      // Call fetchProjects with the current filters after debounce
      fetchProjects(1, filters);
    }, 1000);

    // Call the debounced function
    fetchSelfPlansDebounced();

    // Return a cleanup function to cancel debounced calls on component unmount or re-render
    return () => {
      fetchSelfPlansDebounced.cancel();
    };
  };

  const handleFilterClear = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      industry: "",
      spaceSize: [0, 50000],
      search: "",
    }));
    setProjectData([]);
    setCurrentPage(1);
    fetchProjects(1, {});
    setFilterMenu(false);
  };
  const handleSpaceSizeChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      spaceSize: value,
    }));
  };

  return (
    <div>
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-50 w-80 h-screen pt-5 pb-14 transition-transform ${
          filterMenu ? "translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-200`}
        aria-label="Sidebar">
        <div className="flex justify-between px-3 items-center mb-5">
          <h2 className="text-2xl font-bold">Filter</h2>
          <div onClick={() => setFilterMenu(false)} className="bg-[#efefef] p-2 rounded-full">
            <Icon icon="humbleicons:times" />
          </div>
        </div>

        <div className="h-full px-3 py-4 overflow-y-auto">
          <div className="mb-4">
            <label className="text-sm font-semibold block mb-2 tracking-wider">Industry : </label>

            <select
              name="industry"
              value={filters.industry}
              onChange={handleInputChange}
              className="py-2 px-3 border w-full rounded-lg outline-primary-500"
              placeholder="Enter industry here...">
              <option value="" disabled>
                Select industry
              </option>
              {industries.map((industry, idx) => (
                <option key={idx} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
          </div>

          <div className="relative mb-10">
            <label className="text-sm font-semibold block mb-2 tracking-wider">Space-Size Range in (Sqft) : </label>
            <MultiRangeSlider min={0} max={50000} value={filters?.spaceSize} onChange={handleSpaceSizeChange} />
          </div>

          {(filters.spaceSize[0] !== 0 || filters.spaceSize[1] !== 50000 || filters.industry) && (
            <div className="flex gap-3 items-center mt-10 justify-end">
              <button
                className="mt-5 py-2 px-3 bg-white border-2 border-primary-500 text-primary-500 rounded-lg flex gap-2 items-center justify-center"
                onClick={() => {
                  handleFilterClear();
                }}>
                <Icon icon="humbleicons:times" /> Clear
              </button>
              <button
                className="mt-5 py-2 px-3 bg-primary-500 border-2 border-primary-500 text-white rounded-lg flex gap-2 items-center justify-center"
                onClick={() => {
                  fetchProjects(1, filters);
                  setFilterMenu(false);
                }}>
                <Icon icon="humbleicons:check" /> Apply
              </button>
            </div>
          )}
        </div>
      </aside>
      <div className="flex flex-wrap items-center justify-between gap-3 p-3 shadow-md fixed w-full z-40 bg-white">
        <div className="grow md:grow-0">
          <img width={120} src={Logo} />
        </div>
        <div className="flex gap-3 w-full max-w-[600px]">
          <div className="border-2 rounded-lg overflow-hidden w-full relative">
            <input
              name="search"
              value={filters.search}
              onChange={handleSearchChange}
              placeholder="Search by Keywords..."
              className="w-full py-2 pl-8 pr-3 border-none outline-none"
            />
            <Icon icon={"ion:search"} className="absolute top-1/2 -translate-y-1/2 left-2 text-2xl text-gray-400" />
          </div>
          <button
            className="py-2 px-3 bg-primary-500 text-white rounded-lg flex gap-2 items-center justify-center"
            onClick={() => setFilterMenu(true)}>
            <Icon icon="octicon:filter-16" /> Filters
          </button>
        </div>
      </div>
      <div className="px-3 mx-auto container project-list-public min-h-screen pt-36 md:pt-20">
        <InfiniteScroll
          dataLength={projectData.length}
          next={fetchMoreProjects}
          hasMore={hasMore}
          loader={<p className="text-center text-teal-500">Loading.....</p>}>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 950: 3, 1400: 4 }}>
            <Masonry gutter={"1.2rem"}>
              {projectData.map((item, idx) => (
                <Link
                  to={`/project-detail/${item._id}`}
                  key={idx}
                  className="relative group rounded-3xl overflow-hidden border">
                  <div className="rounded-xl z-10 cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
                    <div>
                      <div className="p-4 text-xl pb-10">
                        <h2 className="font-bold">{item?.title}</h2>
                        <p className="opacity-60 text-sm ">
                          {item?.spaceSize} Sqft
                          <br />
                          {item?.location?.city}, {item?.location?.state}
                        </p>
                      </div>
                    </div>
                  </div>
                  <img
                    alt={item?.title}
                    loading="lazy"
                    className="object-cover min-h-[300px] w-full group-hover:scale-110 block transition-all duration-300 ease"
                    src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item?.thumbNail}`}
                  />
                </Link>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ProjectListing;
