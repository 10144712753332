import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";
import * as Unicons from "@iconscout/react-unicons";

const NavLinks = () => {
  const [heading, setHeading] = useState("");

  const generateKey = (link, sublink) => `${link.name}_${sublink.name}`;

  return (
    <>
      {links.map((link, index) => (
        <div key={index} className="md:mb-0 mb-4">
          <div className="bg-slate-200 mb-3 md:mb-0 md:bg-[rgba(255,255,255,0.9)] group relative px-3 py-2 md:py-0.5 rounded-lg md:border md:border-slate-400 md:hover:bg-primary-500 cursor-pointer md:hover:text-white">
            <h1
              className="py-1 flex justify-between items-center group md:text-sm"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
              }}
            >
              
              <span className="inline-flex gap-2 items-center">{link.icon}{link.name}</span>
              <span className="text-xl md:hidden inline">
                {heading === link.name ? <Unicons.UilAngleUp /> : <Unicons.UilAngleDown />}
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180">
                <Unicons.UilAngleDown />
              </span>
            </h1>
            {link.submenu && (
              <div>
                <div className="absolute top-5 left-0 z-20 hidden group-hover:md:block hover:md:block w-full">
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 -z-10 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white pl-3 pr-1 py-3 rounded-md shadow-lg max-h-[270px] overflow-y-scroll">
                    {link.sublink.map((mysublinks, subIndex) => (
                      <Link
                        key={generateKey(link, mysublinks)}
                        to={`${process.env.REACT_APP_FRONT_URL}/showcase?tags=${mysublinks.name
                          .toLowerCase()
                          .replace(/\s+/g, "_")
                          .replace(/\//g, "-")}`}
                      >
                        <li className="text-sm text-gray-600 p-2 rounded hover:bg-gray-100 hover:text-teal-600">
                          {mysublinks.name}
                        </li>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublink.map((slinks, subIndex) => (
              <ul key={generateKey(link, slinks)}>
                <li className="py-1 text-sm text-slate-500 md:py-3 pl-5">
                  <Link to={slinks.link}>{slinks.name}</Link>
                </li>
              </ul>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
