import React, { useEffect, useState, useRef } from "react";
import { Stage, Layer, Rect, Transformer } from "react-konva";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import bg from "../../assets/topbar/home_4.jpg";
import { useParams, useNavigate } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import Loader from "./../../components/Loader";
import Layout from "./../Layout";

const EditImageMapper = () => {
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);
  const { floorId } = useParams();
  const navigate = useNavigate();
  const [floorPlanData, setFloorPlanData] = useState({});
  const [area, setArea] = useState([]);
  const [loading, setLoading] = useState(true);
  const [generatedCoordinates, setGeneratedCoordinates] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");

  const labelOptions = [
    "boss_cabin",
    "open_office",
    "manager_cabin",
    "work_lounge",
    "pantry_area-coffee_point",
    "reception-waiting_area",
    "restroom",
    "cafeteria",
    "boardroom",
    "small_meeting_room",
    "large_meeting_room",
  ];

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  const getFloorPlan = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/api/floor/self-floorplan/${floorId}`,
        axiosConfig,
      );
      setFloorPlanData(response.data);
      if (response.data.maps.length > 0) {
        setArea(
          response.data.maps.map((item, index) => ({
            id: index,
            label: item.Tag,
            coords: item.coords,
          })),
        );
      } else {
        toast.error("No area maps found !!");
      }
    } catch (error) {
      console.error("Failed to fetch floor plan:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFloorPlan();
  }, [floorId]);

  useEffect(() => {
    const handleGenerateCoordinates = () => {
      const coordinates = area.map(({ id, label, coords }) => ({
        id,
        Tag: label,
        coords,
      }));

      setGeneratedCoordinates(coordinates);
    };

    handleGenerateCoordinates();
  }, [area]);

  const handleRectClicked = (e, id) => {
    setArea((prevAreas) =>
      prevAreas.map((rectObj) =>
        rectObj.id === id
          ? { ...rectObj, isSelected: true, selectedNode: e.target }
          : { ...rectObj, isSelected: false, selectedNode: null },
      ),
    );
  };

  const handleAddRect = () => {
    if (selectedLabel !== "") {
      const newRect = {
        id: area.length + 1,
        label: selectedLabel,
        coords: [10, 10, 100, 100],
      };

      setArea((prevAreas) => [...prevAreas, newRect]);
      setSelectedLabel(""); // Reset to the default value after adding
    }
  };

  const handleRemoveRect = (id) => {
    const updatedAreas = area.filter((rect) => rect.id !== id);
    setArea(updatedAreas);
  };

  const handleRectDragEnd = (e, id) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1); // Reset the scale after transforming
    node.scaleY(1);

    const newCoords = [node.x(), node.y(), node.x() + node.width() * scaleX, node.y() + node.height() * scaleY];

    setArea((prevAreas) =>
      prevAreas.map((rectObj) =>
        rectObj.id === id
          ? {
              ...rectObj,
              isSelected: true,
              selectedNode: e.target,
              coords: newCoords,
            }
          : { ...rectObj, isSelected: false, selectedNode: null },
      ),
    );
  };

  const saveUpdatedPlan = async () => {
    let loadingToastId; // Declare loadingToastId variable outside try-catch block
    try {
      loadingToastId = toast.loading("Saving FloorPlan data...");
      const response = await axios.put(
        `${process.env.REACT_APP_BACK_URL}/api/floor/self-floorplan/${floorId}`,
        {
          maps: generatedCoordinates,
        },
        axiosConfig,
      );

      if (response.status === 200) {
        toast.update(loadingToastId, {
          render: "Successfully updated floor plan!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
        getFloorPlan();
      } else {
        toast.update(loadingToastId, {
          render: "Error updating floor plan.",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Error updating floor plan.",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-3xl p-3 text-center font-bold  text-ellipsis overflow-hidden max-w-screen-xl mx-auto whitespace-nowrap">
            {floorPlanData.projectName ?? "No Name"}
          </h2>

          <div className="w-full max-w-screen-xl mx-auto min-h-screen p-5">
            <div>
              <div className="border-2 p-3 rounded-lg">
                <form className="flex gap-3 items-center">
                  <select
                    className="w-full border rounded p-3"
                    value={selectedLabel}
                    onChange={(e) => setSelectedLabel(e.target.value)}
                  >
                    <option value="">Choose a label from here....</option>
                    {labelOptions.map((label) => {
                      const formattedLabel = label
                        .replace(/_/g, " ")
                        .replace(/-/g, " & ")
                        .split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ");
                      return (
                        <option key={label} value={label}>
                          {formattedLabel}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    disabled={selectedLabel === ""}
                    className="bg-green-500 text-white p-3 rounded w-28 disabled:bg-gray-400"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddRect();
                    }}
                  >
                    Add Rect
                  </button>
                </form>
              </div>

              <div className="p-3 bg-slate-100 mt-10 border-2 rounded flex flex-wrap gap-3 justify-center">
                {area.length > 0 &&
                  area.map((item) => {
                    const itemLabel = item.label
                      .replace(/_/g, " ")
                      .replace(/-/g, " & ")
                      .split(" ")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ");
                    return (
                      <div
                        key={item.id}
                        className={`bg-white border-2 p-1 rounded-full flex gap-3 items-center ${
                          item.isSelected && "border-teal-500"
                        }`}
                      >
                        <p className="px-3">{itemLabel}</p>
                        <button
                          className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
                          onClick={() => {
                            handleRemoveRect(item.id);
                          }}
                        >
                          <Unicons.UilTimes size="20" />
                        </button>
                      </div>
                    );
                  })}
              </div>

              <div className="p-5 border-2 rounded-md flex justify-center mt-10 bg-white">
                <div className="relative">
                  <img
                    ref={imageRef}
                    src={`${process.env.REACT_APP_BACK_URL}/api/minio/${floorPlanData.imageUrl}`}
                    alt="Floor Plan"
                    className="w-full max-w-[1000px] mx-auto"
                    onLoad={() => {
                      // Handle image load to set stage size
                      const stageWidth = imageRef.current.clientWidth;
                      const stageHeight = imageRef.current.clientHeight;
                      setStageSize({ width: stageWidth, height: stageHeight });
                    }}
                  />
                  <Stage
                    width={stageSize.width}
                    height={stageSize.height}
                    className="absolute top-0 left-0 z-10 bg-[rgba(0,0,0,0.1)]"
                  >
                    <Layer>
                      {area.map((rectObj) => (
                        <React.Fragment key={rectObj.id}>
                          <Rect
                            x={rectObj.coords[0]}
                            y={rectObj.coords[1]}
                            width={rectObj.coords[2] - rectObj.coords[0]}
                            height={rectObj.coords[3] - rectObj.coords[1]}
                            fill={rectObj?.isSelected ? "green" : "black"}
                            opacity={0.5}
                            onClick={(e) => handleRectClicked(e, rectObj.id)}
                            onDragEnd={(e) => handleRectDragEnd(e, rectObj.id)}
                            draggable={rectObj?.isSelected || false}
                          />
                          {rectObj.selectedNode && (
                            <Transformer
                              anchorSize={8}
                              borderDash={[6, 2]}
                              rotateEnabled={false}
                              onTransformEnd={(e) => {
                                // Update coordinates after transform
                                const node = rectObj.selectedNode;
                                const scaleX = node.scaleX();
                                const scaleY = node.scaleY();
                                node.scaleX(1);
                                node.scaleY(1);

                                const newCoords = [
                                  node.x(),
                                  node.y(),
                                  node.x() + node.width() * scaleX,
                                  node.y() + node.height() * scaleY,
                                ];

                                setArea((prevAreas) =>
                                  prevAreas.map((rectObjInner) =>
                                    rectObjInner.id === rectObj.id
                                      ? {
                                          ...rectObjInner,
                                          isSelected: true,
                                          selectedNode: node,
                                          coords: newCoords,
                                        }
                                      : rectObjInner,
                                  ),
                                );
                              }}
                              ref={(node) => {
                                if (node && rectObj?.isSelected) {
                                  node.nodes([rectObj.selectedNode]);
                                }
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Layer>
                  </Stage>
                </div>
              </div>

              <div className="my-5 justify-center flex gap-10">
                <button className="py-2.5 px-3 bg-green-500 text-white rounded" onClick={saveUpdatedPlan}>
                  Update FloorPlan
                </button>

                <button
                  className="py-2.5 px-3 bg-green-500 text-white rounded"
                  onClick={() => navigate(`/self-moodboard/floorId/${floorId}`)}
                >
                  Go to Moodboard
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default EditImageMapper;
