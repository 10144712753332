import React, { useState } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
  const [passShow, setPassShow] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.fname || !formData.email || !formData.password || !formData.cpassword) {
      toast.error("Please fill in all the details");
      return;
    }

    if (formData.password !== formData.cpassword) {
      toast.error("Password and Confirm Password do not match");
      return;
    }

    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (formData.password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/user/add`, formData, axiosConfig);
      console.log("User created:", response.data);
      toast.success("User created successfully");
      // Optionally, handle redirection or reset form state here
    } catch (error) {
      console.error("Error creating user:", error.response ? error.response.data : error);
      toast.error("An error occurred while creating the user");
    }
  };

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <Layout>
      <div className="flex items-center justify-center py-10 bg-slate-100 min-h-[88vh]">
        <div className="bg-white rounded-2xl border shadow-x1 p-10 w-full max-w-lg">
          <div className="flex items-center mb-8">
            <Link to="/user-list" className="bg-slate-100 w-10 h-10 rounded-full flex items-center justify-center">
              <Unicons.UilArrowLeft size={25} />
            </Link>
            <h1 className="flex-grow pr-10 font-bold text-4xl text-gray-700 w-4/6 text-center">Create User</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col items-center space-y-4">
              <input
                type="text"
                name="fname"
                placeholder="Name"
                value={formData.fname}
                onChange={handleChange}
                className="border-2 rounded-lg w-full h-12 px-4 focus:ring-0 focus:border-gray-500"
              />
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="border-2 rounded-lg w-full h-12 px-4 focus:ring-0 focus:border-gray-500"
              />

              <div className="relative w-full">
                <input
                  onChange={handleChange}
                  type={!passShow ? "password" : "text"}
                  value={formData.password}
                  name="password"
                  id="password"
                  placeholder={!passShow ? "••••••••••••" : "Create Password"}
                  className="w-full border-2 rounded-lg h-12 block focus:ring-0 focus:border-gray-500"
                  required
                />
                <span className="absolute inset-y-0 top-2 md:top-3 right-3" onClick={() => setPassShow(!passShow)}>
                  {!passShow ? <Unicons.UilEye color="gray" /> : <Unicons.UilEyeSlash color="gray" />}
                </span>
              </div>

              <input
                type="password"
                name="cpassword"
                placeholder="Confirm Password"
                value={formData.cpassword}
                onChange={handleChange}
                className="border-2 rounded-lg w-full h-12 px-4 focus:ring-0 focus:border-gray-500"
              />
              <button
                type="submit"
                className="bg-teal-500 text-white rounded-md hover:bg-teal-600 font-semibold px-4 py-3 w-full"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddUser;
