import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Imgplc from "../assets/imgplace.png";
import Imgplc2 from "../assets/placeholder-600x400.webp";

const Img = ({ src, className, alt }) => {
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent infinite loop in case of repeated error
    e.target.src = Imgplc2; // Set placeholder image when main image fails to load
  };

  return (
    <LazyLoadImage
      alt={alt}
      effect="blur"
      src={src}
      placeholderSrc={Imgplc}
      className={className}
      onError={handleImageError}
    />
  );
};

export default Img;
