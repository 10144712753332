import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  moodBoardUser: {
    company: "",
    direction: "",
    location: "",
    number: "",
  },
};

const moodBoardUserSlice = createSlice({
  name: "moodBoardUser",
  initialState,
  reducers: {
    setmoodBoardUser: (state, action) => {
      state.moodBoardUser = action.payload;
    },
  },
});

export const { setmoodBoardUser } = moodBoardUserSlice.actions;
export default moodBoardUserSlice.reducer;
