import {
  UilBriefcase,
  UilMapMarkerEdit,
  UilLightbulbAlt,
  UilLamp,
  UilCoffee,
  UilWall,
  UilUsersAlt,
  UilMoneyBillStack,
  UilNotes
} from "@iconscout/react-unicons"; // Import the Unicons icons you need

export const links = [
  {
    name: "Work Areas",
    icon: <UilBriefcase />,
    submenu: true,
    sublink: [
      { name: "Open Office", link: "/" },
      { name: "Manager Cabin", link: "/" },
      { name: "Boss Cabin", link: "/" },
      { name: "Work Lounge", link: "/" },
    ],
  },
  {
    name: "Meeting Spaces",
    icon: <UilUsersAlt />,
    submenu: true,
    sublink: [
      { name: "Assembly", imgLink: "/FI/8265" },
      { name: "Boardroom", imgLink: "/ABA/9234" },
      { name: "Brainstorm Room", imgLink: "/JJ/2865" },
      { name: "Breakout Space", imgLink: "/BV/3878" },
      { name: "Large Meeting Room", imgLink: "/E/1605" },
      { name: "Large Open Meeting Space", imgLink: "/K/3407" },
      { name: "Small Meeting Room", imgLink: "/V/3686" },
      { name: "Small Open Meeting Space", imgLink: "/IN/5984" },
      { name: "Training Room", imgLink: "/AT/9158" },
    ],
  },
  {
    name: "Support Spaces",
    icon: <UilMoneyBillStack/>,
    submenu: true,
    sublink: [
      { name: "Bar", imgLink: "/ME/8912" },
      { name: "Cafeteria", imgLink: "/NJ/8567" },
      { name: "Elevator Lobby", imgLink: "/ABZ/9539" },
      { name: "Games Room", imgLink: "/AG/8954" },
      { name: "Locker Area", imgLink: "/MH/4192" },
      { name: "Pantry Area/Coffee Point", imgLink: "/S/8686" },
      { name: "Phone/Study Booth", imgLink: "/A/7744" },
      { name: "Reception/Waiting Area", imgLink: "/AI/4071" },
      { name: "Restroom", imgLink: "/UY/8757" },
    ],
  },

  {
    name: "Features",
    icon: <UilMapMarkerEdit />,
    submenu: true,
    sublink: [
      { name: "Acoustic Ceiling Baffle", link: "/" },
      { name: "Acoustic Ceiling Panel", link: "/" },
      { name: "Booking Screen", link: "/" },
      { name: "Ceiling Fans", link: "/" },
      { name: "Ceiling-Mounted Display", link: "/" },
      { name: "Chalkboard", link: "/" },
      { name: "Column", link: "/" },
      { name: "Curtain", link: "/" },
      { name: "Details/Close-up Photos", link: "/" },
      { name: "Exposed Duct", link: "/" },
      { name: "Exterior", link: "/" },
      { name: "Folding/Moveable Walls", link: "/" },
      { name: "Glass Walls", link: "/" },
      { name: "Green Wall", link: "/" },
      { name: "Guardrail", link: "/" },
      { name: "Hallway", link: "/" },
      { name: "Lectern", link: "/" },
      { name: "Partition", link: "/" },
      { name: "Pin Board", link: "/" },
      { name: "Plans/Drawings", link: "/" },
      { name: "Plants", link: "/" },
      { name: "Projection Screen", link: "/" },
      { name: "Shipping Containers", link: "/" },
      { name: "Slides", link: "/" },
      { name: "Spiral Stairs", link: "/" },
      { name: "Stairs", link: "/" },
      { name: "Terrace", link: "/" },
      { name: "Video Conferencing", link: "/" },
      { name: "Video Wall", link: "/" },
      { name: "Wall-Mounted Display", link: "/" },
      { name: "Whiteboard", link: "/" },
      { name: "Whiteboard Wall", link: "/" },
    ],
  },

  {
    name: "Flooring",
    icon: <UilNotes/>,
    submenu: true,
    sublink: [
      { name: "Area Rug", link: "/" },
      { name: "Carpet", link: "/" },
      { name: "Cement Floor", link: "/" },
      { name: "Parquet Floor", link: "/" },
      { name: "Wood Floor", link: "/" },
    ],
  },

  // {
  //   name: "Lighting",
  //   icon: <UilLightbulbAlt />,
  //   submenu: true,
  //   sublink: [
  //     { name: "Backlight/Panel", link: "/" },
  //     { name: "Bare Bulb", link: "/" },
  //     { name: "Chandelier", link: "/" },
  //     { name: "Cove Lighting", link: "/" },
  //     { name: "Cylinder/Round", link: "/" },
  //     { name: "Floor Lamp", link: "/" },
  //     { name: "In-floor", link: "/" },
  //     { name: "Lay-In/Troffer", link: "/" },
  //     { name: "Linear", link: "/" },
  //     { name: "Neon", link: "/" },
  //     { name: "Pendant", link: "/" },
  //     { name: "Perimeter/Grazer", link: "/" },
  //     { name: "Recessed Downlight", link: "/" },
  //     { name: "Recessed Lighting", link: "/" },
  //     { name: "Sconce", link: "/" },
  //     { name: "Stair and Handrail", link: "/" },
  //     { name: "Table Lamp", link: "/" },
  //     { name: "Task Light", link: "/" },
  //     { name: "Track/Directional", link: "/" },
  //   ],
  // },

  // {
  //   name: "Materials",
  //   icon: <UilWall />,
  //   submenu: true,
  //   sublink: [
  //     { name: "Brick", link: "/" },
  //     { name: "Wood", link: "/" },
  //   ],
  // },

  // {
  //   name: "Environmental Graphics",
  //   icon: <UilLamp />,
  //   submenu: true,
  //   sublink: [
  //     { name: "Branding", link: "/" },
  //     { name: "Glass Graphics", link: "/" },
  //     { name: "Signs And Wayfinding", link: "/" },
  //     { name: "Street Art", link: "/" },
  //     { name: "Wall Graphics", link: "/" },
  //   ],
  // },

  {
    name: "Seating & Tables",
    icon: <UilCoffee />,
    submenu: true,
    sublink: [
      { name: "Banquette Seating", link: "/" },
      { name: "Bar Stool", link: "/" },
      { name: "Bench", link: "/" },
      { name: "Booth Seating", link: "/" },
      { name: "Chair", link: "/" },
      { name: "Coffee Table", link: "/" },
      { name: "Game/Billiards Table", link: "/" },
      { name: "Highback Sofa/Chair", link: "/" },
      { name: "Lounge Chair", link: "/" },
      { name: "Low Stool", link: "/" },
      { name: "Occasional Table", link: "/" },
      { name: "Ottoman/Pouf", link: "/" },
      { name: "Perch/Ledge", link: "/" },
      { name: "Personal/Laptop Table", link: "/" },
      { name: "Picnic Table", link: "/" },
      { name: "Task Chair", link: "/" },
      { name: "Task Stool", link: "/" },
      { name: "Tiered-Seating", link: "/" },
      { name: "Training Table", link: "/" },
    ],
  },
];
