import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../../Layout";
import ToggleButton from "../../../components/formComponents/ToggleButton";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { validationSchema, industries } from "./validationSchema";
import ImgDrop from "../../../assets/na7p8lprxplx309m10.png";
import { Link, useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import * as Unicons from "@iconscout/react-unicons";
import {
  GetState,
  GetCity, //async functions
} from "react-country-state-city";

const ProjectShowcaseAdd = () => {
  let initialFormData = {
    title: "",
    subTitle: "",
    spaceSize: 0,
    location: {
      state: "",
      city: "",
    },
    industry: "",
    virtualLink: "",
    youtubeLink: "",
    sliderImages: [], // For slider images
    beforeAfterImages: [],
    thumbNail: [],
    textSection: [
      {
        title: "",
        subTitle: "",
        description: "",
        textSectionImgs: [], // For text section images
        isActive: false,
      },
    ],
    isLive: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isBeforeAfterOpen, setIsBeforeAfterOpen] = useState(false);
  const [isThumbNailOpen, setIsThumbNailOpen] = useState(false);
  const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false); // State to manage popup visibility
  const [isTextSectionImageSelectorOpen, setIsTextSectionImageSelectorOpen] = useState(false); // State to manage popup visibility
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/project-showcases/${id}`);
        setFormData((prev) => ({
          ...prev,
          ...response.data,
        }));
      } catch (error) {
        console.error("Failed to fetch project data", error);
      }
    };

    fetchProject();
  }, [id]);

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSectionChange = (e, index, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      textSection: prevData.textSection.map((section, idx) =>
        idx === index ? { ...section, [field]: value } : section,
      ),
    }));
  };

  const handleToggle = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      textSection: prevData.textSection.map((section, idx) =>
        idx === index ? { ...section, isActive: !section.isActive } : section,
      ),
    }));
  };

  const addSection = () => {
    setFormData((prevData) => ({
      ...prevData,
      textSection: [
        ...prevData.textSection,
        { title: "", subTitle: "", description: "", textSectionImgs: [], isActive: false },
      ],
    }));
  };

  const removeSection = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      textSection: prevData.textSection.filter((_, idx) => idx !== index),
    }));
  };

  const removeImage = (index, isSliderImage = 1, sectionIndex = null) => {
    if (isSliderImage == 1) {
      setFormData((prevData) => ({
        ...prevData,
        sliderImages: prevData.sliderImages.filter((_, imgIndex) => imgIndex !== index),
      }));
    } else if (isSliderImage == 2) {
      setFormData((prevData) => ({
        ...prevData,
        beforeAfterImages: prevData.beforeAfterImages.filter((_, imgIndex) => imgIndex !== index),
      }));
    } else if (isSliderImage == 3) {
      setFormData((prevData) => ({
        ...prevData,
        thumbNail: prevData.thumbNail.filter((_, imgIndex) => imgIndex !== index),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        textSection: prevData.textSection.map((section, idx) =>
          idx === sectionIndex
            ? { ...section, textSectionImgs: section.textSectionImgs.filter((_, imgIndex) => imgIndex !== index) }
            : section,
        ),
      }));
    }
  };

  const handleImageSelectionChange = (selectedImages, isSliderImage) => {
    if (isSliderImage === 1) {
      setFormData((prevData) => ({
        ...prevData,
        sliderImages: selectedImages,
      }));
    } else if (isSliderImage === 2) {
      setFormData((prevData) => ({
        ...prevData,
        beforeAfterImages: selectedImages,
      }));
    } else if (isSliderImage === 3) {
      setFormData((prevData) => ({
        ...prevData,
        thumbNail: selectedImages,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        textSection: prevData.textSection.map((section, idx) => {
          if (idx === currentSectionIndex) {
            return { ...section, textSectionImgs: selectedImages };
          }
          return section;
        }),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let toastId = toast.loading("Saving...");
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const response = await axios.put(`${process.env.REACT_APP_BACK_URL}/api/project-showcases/${id}`, formData, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem("usersdatatoken") },
      });
      if (response.status === 200) {
        toast.update(toastId, {
          render: "Project showcase created.",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
        setErrors({});
        console.log("Project showcase created:", response.data);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.update(toastId, {
          render: "Validation errors",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
        console.log(validationErrors);
      } else {
        toast.update(toastId, {
          render: "There was an error creating the project showcase!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
        console.error("There was an error creating the project showcase!", error);
      }
    }
  };

  const openTextSectionImageSelector = (index) => {
    setCurrentSectionIndex(index);
    setIsTextSectionImageSelectorOpen(true);
  };

  const closeTextSectionImageSelector = () => {
    setCurrentSectionIndex(null);
    setIsTextSectionImageSelectorOpen(false);
  };

  const openImageSelector = () => {
    setIsImageSelectorOpen(true);
  };

  const closeImageSelector = () => {
    setIsImageSelectorOpen(false);
  };

  const openBeforeAfter = () => {
    setIsBeforeAfterOpen(true);
  };

  const closeBeforeAfter = () => {
    setIsBeforeAfterOpen(false);
  };

  const openThumbNail = () => {
    setIsThumbNailOpen(true);
  };

  const closeThumbNail = () => {
    setIsThumbNailOpen(false);
  };

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    GetState(101).then((result) => {
      setStateList(result);
    });
    const state = stateList.find((state) => state.name === formData.location.state);
    GetCity(101, state?.id).then((result) => {
      setCityList(result);
    });
  }, [formData.location]);

  const handleStateChange = (e) => {
    const state = stateList.find((state) => state.name === e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      location: {
        ...prevData.location,
        state: e.target.value,
        city: "",
      },
    }));
    GetCity(101, state.id).then((result) => {
      setCityList(result);
    });
  };

  return (
    <Layout>
      <div className="p-3 mx-auto container bg-slate-50">
        <h1 className="text-2xl mt-3 mb-8">Create Project</h1>
        <form onSubmit={handleSubmit}>
          {/* Top Information Section */}
          <div className="px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
            <h2 className="mb-3 text-lg font-bold">Top Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project Title : </label>
                  <input
                    readOnly
                    disabled
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    value={formData?.title}
                    onChange={(e) => handleInputChange(e, "title")}
                    placeholder="Enter project Title..."
                  />
                  {errors.title && <p className="text-red-500 text-xs px-2 pt-1">{errors.title}</p>}
                </div>
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project Sub Title : </label>
                  <input
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    value={formData?.subTitle}
                    onChange={(e) => handleInputChange(e, "subTitle")}
                    placeholder="Enter project Subheading..."
                  />
                  {errors.subTitle && <p className="text-red-500 text-xs px-2 pt-1">{errors.subTitle}</p>}
                </div>
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project Space Size : </label>
                  <input
                    type="number"
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    value={formData?.spaceSize}
                    onChange={(e) => handleInputChange(e, "spaceSize")}
                    placeholder="Enter space size..."
                  />
                  {errors.spaceSize && <p className="text-red-500 text-xs px-2 pt-1">{errors.spaceSize}</p>}
                </div>

                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project Industry : </label>
                  <select
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    value={formData.industry}
                    onChange={(e) => handleInputChange(e, "industry")}>
                    <option value="" disabled>
                      Select industry
                    </option>
                    {industries.map((industry, idx) => (
                      <option key={idx} value={industry}>
                        {industry}
                      </option>
                    ))}
                  </select>
                  {errors.industry && <p className="text-red-500 text-xs px-2 pt-1">{errors.industry}</p>}
                </div>

                <div className="px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
                  <div className="flex justify-between items-center p-2 rounded-lg bg-white mb-4 border">
                    <h2 className="text-sm font-semibold">ThumnNail Image</h2>
                    <button
                      className="bg-blue-500 py-2 px-3 rounded-lg text-white text-xs"
                      type="button"
                      onClick={openThumbNail}>
                      Choose Images
                    </button>
                  </div>

                  <ImagePreviewer
                    images={formData.projectImages}
                    grid={4}
                    selectedImages={formData.thumbNail}
                    removeImage={(index) => removeImage(index, 3)}
                  />
                  {errors.thumbNail && <p className="text-red-500 text-xs px-2 pt-1">{errors.thumbNail}</p>}
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project State : </label>
                  <select
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    onChange={handleStateChange}
                    value={formData.location.state}>
                    {stateList.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project City : </label>
                  <select
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        location: {
                          ...prevData.location,
                          city: e.target.value,
                        },
                      }));
                    }}
                    value={formData.location.city}>
                    <option value="" disabled>
                      Select City Name
                    </option>
                    {cityList.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project Virtual Link : </label>
                  <input
                    type="url"
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    value={formData.virtualLink}
                    onChange={(e) => handleInputChange(e, "virtualLink")}
                    placeholder="Enter Virtual Image Url..."
                  />
                  {errors.virtualLink && <p className="text-red-500 text-xs px-2 pt-1">{errors.virtualLink}</p>}
                </div>
                <div>
                  <label className="text-sm font-semibold block mb-2 tracking-wider">Project Youtube Link : </label>
                  <input
                    type="url"
                    className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                    value={formData.youtubeLink}
                    onChange={(e) => handleInputChange(e, "youtubeLink")}
                    placeholder="Enter Youtube Url..."
                  />
                  {errors.youtubeLink && <p className="text-red-500 text-xs px-2 pt-1">{errors.youtubeLink}</p>}
                </div>

                <div className="px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
                  <div className="flex justify-between items-center p-2 rounded-lg bg-white mb-4 border">
                    <h2 className="text-sm font-semibold">Before After Images</h2>
                    <button
                      className="bg-blue-500 py-2 px-3 rounded-lg text-white text-xs"
                      type="button"
                      onClick={openBeforeAfter}>
                      Choose Images
                    </button>
                  </div>

                  <ImagePreviewer
                    images={formData.projectImages}
                    grid={4}
                    selectedImages={formData.beforeAfterImages}
                    removeImage={(index) => removeImage(index, 2)}
                  />
                  {errors.beforeAfterImages && (
                    <p className="text-red-500 text-xs px-2 pt-1">{errors.beforeAfterImages}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Image Selection Section */}
          <div className="px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
            <div className="flex justify-between items-center px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
              <h2 className="text-lg font-bold">Slider Images</h2>
              <button className="bg-blue-500 py-2 px-3 rounded-lg text-white" type="button" onClick={openImageSelector}>
                Choose Images
              </button>
            </div>

            <ImagePreviewer
              images={formData.projectImages}
              selectedImages={formData.sliderImages}
              removeImage={(index) => removeImage(index, 1)}
            />
            {errors.sliderImages && <p className="text-red-500 text-xs px-2 pt-1">{errors.sliderImages}</p>}
          </div>

          <div className="my-5 flex justify-between items-center">
            <h2 className="text-xl font-semibold">Dynamic Text Sections</h2>
            <button className="bg-green-500 py-2 px-3 rounded-lg text-white" type="button" onClick={addSection}>
              Add Text Section
            </button>
          </div>
          {/* Text Sections */}
          {formData.textSection.map((section, index) => (
            <div key={index} className="px-4 pt-4 pb-5 rounded-lg bg-white mb-4 border">
              <div className="flex items-center justify-between mb-3">
                <h2 className="text-lg font-bold">Section {index + 1}</h2>
                <div className="flex gap-3">
                  <ToggleButton value={section.isActive} onChange={() => handleToggle(index)} />
                  <button
                    className="bg-red-500 py-2 px-3 rounded-lg text-white"
                    type="button"
                    onClick={() => removeSection(index)}>
                    Remove
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-semibold block mb-2 tracking-wider">Section Title : </label>
                    <input
                      className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                      value={section.title}
                      onChange={(e) => handleSectionChange(e, index, "title")}
                      placeholder="Enter section title..."
                    />
                    {errors[`textSection[${index}].title`] && (
                      <p className="text-red-500 text-xs px-2 pt-1">{errors[`textSection[${index}].title`]}</p>
                    )}
                  </div>

                  <div>
                    <label className="text-sm font-semibold block mb-2 tracking-wider">Section Description : </label>
                    <textarea
                      className="py-2 px-3 border w-full rounded-lg outline-primary-500 text-md"
                      value={section.description}
                      rows={6}
                      onChange={(e) => handleSectionChange(e, index, "description")}
                      placeholder="Enter section description..."
                    />
                    {errors[`textSection[${index}].description`] && (
                      <p className="text-red-500 text-xs px-2 pt-1">{errors[`textSection[${index}].description`]}</p>
                    )}
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-semibold block mb-2 tracking-wider">Section Sub Title : </label>
                    <input
                      className="py-2 px-3 border w-full rounded-lg outline-primary-500"
                      value={section.subTitle}
                      onChange={(e) => handleSectionChange(e, index, "subTitle")}
                      placeholder="Enter section subtitle..."
                    />
                    {errors[`textSection[${index}].subTitle`] && (
                      <p className="text-red-500 text-xs px-2 pt-1">{errors[`textSection[${index}].subTitle`]}</p>
                    )}
                  </div>

                  <div className="flex justify-between items-center p-2 rounded-lg bg-white border">
                    <h2 className="text-sm font-bold">Section Images</h2>
                    <button
                      className="bg-blue-500 py-2 px-3 rounded-lg text-white text-xs"
                      type="button"
                      onClick={() => openTextSectionImageSelector(index)}>
                      Choose Images
                    </button>
                  </div>

                  <ImagePreviewer
                    images={formData.projectImages}
                    grid={4}
                    selectedImages={section.textSectionImgs}
                    removeImage={(imgIndex) => removeImage(imgIndex, false, index)}
                  />
                </div>

                {errors[`textSection[${index}].textSectionImgs`] && (
                  <p className="text-red-500 text-xs px-2 pt-1">{errors[`textSection[${index}].textSectionImgs`]}</p>
                )}
              </div>
            </div>
          ))}

          <div className="mt-8 gap-3 flex items-center justify-between">
            <Link
              to={`/project-showcase/${id}/re-upload`}
              className="py-2 text-sm px-2 bg-teal-500 rounded-lg text-white"
              type="submit">
              Re-Upload Images
            </Link>

            <div className="flex gap-4">
              <ToggleButton
                value={formData?.isLive}
                label="Make Live"
                onChange={() => setFormData((prev) => ({ ...prev, isLive: !prev.isLive }))}
              />
              <button className="py-2 px-2 text-sm bg-teal-500 rounded-lg text-white" type="submit">
                Submit Project
              </button>
            </div>
          </div>
        </form>
      </div>

      {isImageSelectorOpen && (
        <ImageSelectorPopup
          images={formData.projectImages} // Provide images to select from
          selectedImages={formData.sliderImages} // Pass selectedImages state
          onChange={(selectedImages) => handleImageSelectionChange(selectedImages, 1)} // Handle image selection change
          onClose={closeImageSelector} // Close popup function
        />
      )}

      {isBeforeAfterOpen && (
        <ImageSelectorPopup
          images={formData.projectImages} // Provide images to select from
          selectedImages={formData.beforeAfterImages} // Pass selectedImages state
          onChange={(selectedImages) => handleImageSelectionChange(selectedImages, 2)} // Handle image selection change
          onClose={closeBeforeAfter} // Close popup function
        />
      )}

      {isThumbNailOpen && (
        <ImageSelectorPopup
          images={formData.projectImages} // Provide images to select from
          selectedImages={formData.thumbNail} // Pass selectedImages state
          onChange={(selectedImages) => handleImageSelectionChange(selectedImages, 3)} // Handle image selection change
          onClose={closeThumbNail} // Close popup function
        />
      )}

      {isTextSectionImageSelectorOpen && (
        <ImageSelectorPopup
          images={formData.projectImages} // Provide images to select from
          selectedImages={formData.textSection[currentSectionIndex]?.textSectionImgs || []} // Pass selectedImages state for current section
          onChange={(selectedImages) => handleImageSelectionChange(selectedImages, false)} // Handle image selection change
          onClose={closeTextSectionImageSelector} // Close popup function
        />
      )}
    </Layout>
  );
};

const ImageSelectorPopup = ({ images, selectedImages = [], onChange, onClose }) => {
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      onChange([...selectedImages, value]); // Add image to selectedImages
    } else {
      onChange(selectedImages.filter((img) => img !== value)); // Remove image from selectedImages
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center pop-backdrop" onClick={onClose}>
      <div className="fixed inset-0">
        <div className="flex items-center justify-center min-h-screen px-4">
          <div
            className="relative w-full max-w-5xl mx-auto rounded-lg shadow bg-white dark:bg-gray-700"
            onClick={(e) => e.stopPropagation()}>
            {/* Modal header */}
            <div className="flex items-start justify-between px-4 rounded-t ">
              <button
                onClick={onClose}
                type="button"
                className="absolute top-3 right-3 sm:-top-2 sm:-right-2 z-50 bg-[#efefef] hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center">
                <Unicons.UilTimes />
              </button>
            </div>
            {/* Modal body */}
            <div className="p-5 h-[80vh] overflow-y-auto">
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 600: 3, 950: 5, 900: 6 }}>
                <Masonry gutter={"0.5rem"}>
                  {images?.map((option, index) => (
                    <div key={index} className="relative mb-2">
                      <input
                        type="checkbox"
                        value={option}
                        checked={selectedImages.includes(option)}
                        onChange={handleCheckboxChange} // Use handleCheckboxChange function
                        id={option}
                        name="checkbox-group"
                        className="hidden"
                      />
                      <label htmlFor={option} className="w-full cursor-pointer">
                        <img
                          src={`${process.env.REACT_APP_BACK_URL}/api/minio/${option}`}
                          alt={option}
                          className="rounded-xl border-2 shadow-lg w-full"
                        />
                        {selectedImages.includes(option) && (
                          <div className="absolute top-[30px] left-[10px] z-10 bg-green-400 rounded-full flex items-center justify-center text-white">
                            <Unicons.UilCheckCircle />
                          </div>
                        )}
                      </label>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
            {/* Modal footer */}
            <div className="flex items-center justify-end p-5 pt-1 rounded-b gap-3">
              <button
                type="button"
                onClick={onClose}
                className="text-white text-sm bg-green-500 hover:bg-green-600 py-2 px-8 rounded-lg">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImagePreviewer = ({ images, selectedImages = [], grid = 10, removeImage }) => {
  return (
    <div className="border w-full rounded-lg p-4 min-h-[100px] max-h-[500px] overflow-y-auto bg-white">
      <div className={`grid grid-cols-${grid} gap-2`}>
        {selectedImages.map((file, index) => (
          <div key={index} className="relative h-20">
            <img
              src={`${process.env.REACT_APP_BACK_URL}/api/minio/${file}`}
              alt={`Selected ${index}`}
              className="w-full h-20 object-cover rounded"
            />
            <Icon
              onClick={() => removeImage(index)}
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full size-5 text-center p-1"
              icon="mage:trash"
            />
          </div>
        ))}
        {!selectedImages.length && (
          <div className="w-full h-full grid place-content-center">
            <img className="w-[200px]" src={ImgDrop} alt="No images selected" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectShowcaseAdd;
