import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/logo-full.png";
import axios from "axios";
import classNames from "classnames";
import ProjectSlider from "./common/ProjectSlider";
import { BeforeAfter } from "../../components/BeforeAfter";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const ProjectShowcase = () => {
  const [projectData, setProjectData] = useState(null);
  const { id } = useParams();

  const galleryRef = useRef(null);

  useEffect(() => {
    if (galleryRef.current) {
      const lightbox = new PhotoSwipeLightbox({
        gallery: galleryRef.current,
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();

      return () => {
        lightbox.destroy();
      };
    }
  }, [projectData]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/project-showcases/${id}`);
        setProjectData(response.data);
      } catch (error) {
        console.error("Failed to fetch project data", error);
      }
    };

    fetchProject();
  }, [id]);

  if (!projectData) {
    return null;
  }

  const {
    title,
    subTitle,
    sliderImages,
    spaceSize,
    industry,
    location,
    textSection,
    beforeAfterImages,
    virtualLink,
    youtubeLink,
  } = projectData;

  return (
    <div>
      <div className="flex items-center justify-between mb-5 p-3">
        <img width={120} src={Logo} />
      </div>
      <div className="p-3 xl:max-w-screen-lg xl:px-0 2xl:max-w-screen-xl mx-auto bg-white">
        <div className="mb-4">
          <h1 className="text-3xl font-bold text-primary-500">{title}</h1>
          <p className="text-sm w-full max-w-screen-lg">{subTitle}</p>
        </div>

        <ProjectSlider sliderImages={sliderImages} />

        <div className="flex flex-wrap gap-8 justify-center mb-12">
          <div className="flex items-center gap-2">
            <h3 className="text-xl">Space Size - </h3>
            <p>{spaceSize} sqft</p>
          </div>
          <div className="flex items-center gap-2">
            <h3 className="text-xl">Industry - </h3>
            <p>{industry}</p>
          </div>
          <div className="flex items-center gap-2">
            <h3 className="text-xl">Location - </h3>
            <p>
              {location.city}, {location.state}
            </p>
          </div>
        </div>

        <div ref={galleryRef}>
          {textSection
            ?.filter((item) => item.isActive === true)
            .map((item, idx) => (
              <div key={idx} className="relative">
                <div
                  className={classNames("mb-4 flex flex-wrap py-10 px-5 items-center justify-center rounded-3xl", {
                    "flex-row-reverse bg-primary-500 text-white": idx % 2 === 0,
                  })}>
                  <div className="p-4 w-full md:w-2/5">
                    <h2 className="text-2xl mb-3 font-bold">{item.title}</h2>
                    <h3 className="text-lg mb-3 font-semibold">{item.subTitle}</h3>
                    <p>{item.description}</p>
                  </div>
                  <div className="w-full md:w-3/5 relative">
                    <a
                      target="_blank"
                      href={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.textSectionImgs[0]}`}
                      data-pswp-width={1200}
                      data-pswp-height={900}
                      className="relative rounded-md sm:rounded-xl cursor-pointer object-cover">
                      <img
                        className="rounded-xl w-full md:w-[80%] h-[400px] object-cover"
                        src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.textSectionImgs[0]}`}
                        alt="Section main"
                      />
                    </a>
                    <div className="w-full md:w-52 md:absolute top-1/2 md:-translate-y-1/2 right-10 mt-4 md:m-0">
                      {item.textSectionImgs.slice(1).map((img, index) => (
                        <a
                          key={index}
                          target="_blank"
                          href={`${process.env.REACT_APP_BACK_URL}/api/minio/${img}`}
                          data-pswp-width={1200}
                          data-pswp-height={900}
                          className="relative rounded-md sm:rounded-xl cursor-pointer object-cover">
                          <img
                            key={index}
                            className="rounded-2xl mb-3 w-full h-full md:h-32 object-cover md:w-52"
                            src={`${process.env.REACT_APP_BACK_URL}/api/minio/${img}`}
                            alt={`Section ${index + 1}`}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {virtualLink && (
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-primary-500 mb-4 text-center">Virtual Image of Project</h2>
            <div className="mb-4 relative rounded-2xl overflow-hidden">
              <iframe src={virtualLink} width="100%" height="500"></iframe>
            </div>
          </div>
        )}

        {youtubeLink && (
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-primary-500 mb-4 text-center">Video of Project</h2>
            <div className="mb-4 relative rounded-2xl overflow-hidden bg-[#141414]">
              <iframe
                width="100%"
                height={500}
                src={youtubeLink}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </div>
          </div>
        )}

        {beforeAfterImages?.length > 0 && (
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-primary-500 mb-4 text-center">Before vs After View</h2>
            <div className="mb-4 relative rounded-2xl overflow-hidden">
              <BeforeAfter data={beforeAfterImages} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectShowcase;
