import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  subTitle: Yup.string().required("Subheading is required"),
  spaceSize: Yup.number().required("Valid space size is required").positive("Space size must be positive"),
  industry: Yup.string().required("Industry is required"),
  sliderImages: Yup.array().min(5, "At least 5 slider images are required"),
  thumbNail: Yup.array().length(1, "Exactly 1 thumbnail image is required"),
  beforeAfterImages: Yup.array().max(2, "Exactly 2 before and after images are required"),
  youtubeLink: Yup.string().url("Must be a valid URL"),
  virtualLink: Yup.string().url("Must be a valid URL"),
  textSection: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title is required"),
      subTitle: Yup.string().required("Subheading is required"),
      description: Yup.string().required("Description is required"),
      textSectionImgs: Yup.array().min(1, "At least 1 image is required").max(3, "No more than 3 images are allowed"),
    }),
  ),
});

export const industries = ["Startup", "MNC", "Coworking", "SME", "Industrial", "Retail", "Institutional"];
