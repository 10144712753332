import React, { useState } from "react";
import { useForm } from "react-hook-form";
import formImg from "../../assets/userform1.jpg";
import Img from "../../components/Img";
import logo from "../../assets/logo192.png";
import { useDispatch } from "react-redux";
import { setmoodBoardUser } from "../../redux/slicers/moodBoardUser";
import Checkbox from "./../../components/formComponents/CheckBox";
import * as Unicons from "@iconscout/react-unicons";
import { Link, useNavigate } from "react-router-dom";

const UserForm = ({ handleFunction }) => {
  const dispatch = useDispatch();
  const [showJourny, setShowJourny] = useState(false);
  const navigate = useNavigate();
  const directionList = [
    { value: "North", disable: false },
    { value: "South", disable: false },
    { value: "East", disable: false },
    { value: "West", disable: false },
  ];

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const checkedValues = watch("direction", []);

  // Function to update disable property based on checkedValues
  function updateDisableProperty() {
    directionList.forEach((direction) => {
      if (checkedValues.length > 0) {
        // If North is selected, disable only South
        if (checkedValues.includes("North")) {
          direction.disable = direction.value === "South";
        } else if (checkedValues.includes("South")) {
          // Disable all other directions
          direction.disable = direction.value === "North";
        } else {
          // Disable all other directions
          direction.disable = !checkedValues.includes(direction.value);
        }
      } else {
        // If no direction is selected, enable all directions
        direction.disable = false;
      }
    });
  }

  // Call the function initially to set the initial state
  updateDisableProperty();

  // Update disable property whenever checkedValues changes
  watch("direction", () => {
    updateDisableProperty();
  });

  const onSubmit = (data) => {
    if (data.direction.length <= 2) {
      console.log(data);
      dispatch(setmoodBoardUser(data));
      setShowJourny(true);
    }
  };

  return (
    <div className="container mx-auto max-w-screen-xl rounded-xl flex overflow-hidden shadow w-full">
      <div
        style={{
          background: `linear-gradient(45deg, 
                    rgba(0,128,128, 0.5), 
                    rgba(0,128,128, 0.5)),url(${formImg})`,
          backgroundSize: "cover",
        }}
        className="p-10 text-white w-[60%]">
        <Img className="w-16" src={logo} alt="logo" />
        <h3 className="text-5xl font-bold mt-10 mb-4">Create AI Powered MoodBoard in 3 Steps</h3>
        <p className="mb-10 text-lg max-w-lg">
          Leverage our design library of 2,000+ templates across 40 categories to help build your long desired office
        </p>
        <button className="py-2 text-md px-7 rounded-md bg-[#F6CF5A] inline-flex gap-2 items-center">
          Watch Demo <Unicons.UilPlay />
        </button>
      </div>
      <div className="w-[40%] justify-center flex items-center text-white bg-primary-500 p-10">
        {!showJourny ? (
          <div>
            <h2 className="text-2xl font-bold mb-8">Sign up for a personalized moodboard</h2>
            <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  placeholder="Enter you Company Name"
                  className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-[#F6CF5A] block w-full p-2.5"
                  {...register("company", {
                    required: true,
                  })}
                />
                {errors?.company?.type === "required" && (
                  <p className="text-red-500 text-xs">Company name is required</p>
                )}
              </div>
              <div>
                <input
                  placeholder="Enter you Location Name"
                  className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-[#F6CF5A] block w-full p-2.5"
                  {...register("location", {
                    required: true,
                  })}
                />
                {errors?.location?.type === "required" && <p className="text-red-500 text-xs">Location is required</p>}
              </div>
              <div>
                <input
                  placeholder="Enter you Contact Number"
                  className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-[#F6CF5A] block w-full p-2.5"
                  {...register("number", {
                    required: true,
                    pattern: /^[0-9]+$/i,
                  })}
                />
                {errors?.number?.type === "required" && <p className="text-red-500 text-xs">Contact no. is required</p>}
                {errors?.number?.type === "pattern" && <p className="text-red-500 text-xs">Type Numbers only</p>}
              </div>

              <div className="grid grid-cols-2 gap-3">
                {directionList.map((item, index) => (
                  <Checkbox
                    key={index}
                    value={item.value}
                    register={register}
                    disable={item.disable}
                    checkedValues={checkedValues}
                  />
                ))}
              </div>
              {checkedValues?.length > 2 && <p className="text-red-500 text-xs">Max 2 checkboxes only.</p>}
              <div className="text-center pt-6">
                <button className="font-semibold w-full justify-center py-2 text-md tracking-wider px-7 rounded-md bg-[#F6CF5A] inline-flex gap-2 items-center">
                  Start Your Journey <Unicons.UilArrowRight size={32} />
                </button>
                <Link
                  to="image-mapper"
                  className="font-semibold mt-5 w-full justify-center py-2 text-md tracking-wider px-7 rounded-md bg-[#F6CF5A] inline-flex gap-2 items-center">
                  Saved Floorplans <Unicons.UilArrowRight size={32} />
                </Link>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <button className="flex text-sm mb-4 items-center text-[#F6CF5A]" onClick={() => setShowJourny(false)}>
              <Unicons.UilAngleLeft />
              Go Back
            </button>
            <h2 className="text-2xl font-bold mb-8">Choose Moodboard Journeys</h2>

            <button
              onClick={() => handleFunction()}
              className="w-full border-2 border-[#F6CF5A] py-5 px-3 bg-white mb-5 text-teal-500 rounded-md flex justify-between items-center">
              Generate Floor Plan <Unicons.UilRobot />
            </button>
            <button
              onClick={() => navigate("/moodboard/image-mapper")}
              className="w-full border-2 border-[#F6CF5A] py-5 px-3 bg-white text-teal-500 rounded-md flex justify-between items-center">
              Upload Floor Plan <Unicons.UilImageUpload />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserForm;
