import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteUserModal from "../../../components/formComponents/DeleteUserModal";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import * as Unicons from "@iconscout/react-unicons";
import Loader from "./../../../components/Loader";

const ProjectShowcaseList = () => {
  const [projectData, setProjectdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const fetchProjects = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/project-showcases`, {
        params: { page: page, limit: 16 }, // Use the 'page' parameter here
      });
      setProjectdata(response.data.results);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching projects:", error.response ? error.response.data : error);
      toast.error("Something went wrong while fetching projects...");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  useEffect(() => {
    fetchProjects(currentPage);
  }, [currentPage]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  const handleDelete = async () => {
    if (!deleteUserId) return;
    setDelLoading(true);
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/api/project-showcases/${deleteUserId}`, axiosConfig);
      toast.success("Project deleted successfully");
      fetchProjects(currentPage);
    } catch (error) {
      console.error("Error deleting project:", error.response ? error.response.data : error);
      toast.error("Something went wrong...");
    } finally {
      closeDeleteModal();
      setDelLoading(false);
    }
  };

  const openDeleteModal = (projectId) => {
    setDeleteUserId(projectId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteUserId(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <Layout>
      {!loading ? (
        <div className="mx-auto container p-3">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Project Showcase List</h2>
            <div className="flex gap-3">
              <Link to="add" className="bg-primary-500 text-white rounded-lg p-3 text-sm flex gap-1 items-center">
                <Icon className="text-lg" icon="fluent:add-48-filled" /> Add Project
              </Link>
              <Link
                to="/project-list"
                className="bg-primary-500 text-white rounded-lg p-3 text-sm flex gap-1 items-center">
                <Icon className="text-lg" icon="mage:users" /> Public View
              </Link>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {projectData.map((item, idx) => (
              <div
                onClick={() => navigate(`view/${item._id}`)}
                key={idx}
                className="relative border p-2 rounded-xl bg-white cursor-pointer hover:scale-105 hover:bg-slate-100 transition ease-in-out duration-300">
                {!item.isLive && (
                  <span className="absolute top-3 left-3 text-xs text-white bg-red-600 py-1 px-2 rounded-full">
                    Not Live
                  </span>
                )}
                {item.sliderImages.length > 2 && (
                  <div className="flex gap-2 justify-center">
                    <div className="w-2/3">
                      <img
                        className="w-full h-[164px] rounded-lg object-cover"
                        loading="lazy"
                        src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.sliderImages[0]}`}
                        alt="Project"
                      />
                    </div>
                    <div className="w-1/3 flex flex-col gap-2">
                      <img
                        className="w-full h-[77px] rounded-lg object-cover"
                        src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.sliderImages[1]}`}
                        alt="Project"
                        loading="lazy"
                      />
                      <img
                        className="w-full h-[77px] rounded-lg object-cover"
                        src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.sliderImages[2]}`}
                        alt="Project"
                        loading="lazy"
                      />
                    </div>
                  </div>
                )}
                <div className="mt-3 flex gap-2 items-center justify-between">
                  <div className="w-3/4">
                    <p className="text-gray-400 text-xs">{formatDate(item.createdAt)}</p>
                    <h3 className="text-sm font-semibold truncate w-full">{item.title}</h3>
                  </div>
                  <div className="w-1/4 flex gap-2 justify-end">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`${item._id}/add`);
                      }}
                      className="bg-sky-200 size-7 grid place-content-center rounded-lg text-sky-600">
                      <Icon icon="fluent:edit-48-regular" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteModal(item._id);
                      }}
                      className="bg-red-200 size-7 grid place-content-center rounded-lg text-red-600">
                      <Icon icon="fluent:delete-48-regular" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {totalPages > 1 && (
            <div className="flex justify-center m-5">
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                previousLabel={<Unicons.UilAngleLeftB />}
                nextLabel={<Unicons.UilAngleRightB />}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                forcePage={currentPage - 1}
              />
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}

      <DeleteUserModal
        loading={delLoading}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
      />
    </Layout>
  );
};

export default ProjectShowcaseList;
