import React, { useEffect, useState } from "react";
import NavLinks from "./NavLinks";
import * as Unicons from "@iconscout/react-unicons";
import axios from "axios";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <nav className="w-full py-3 bg-[#fafafa]">
      <div className="flex items-center font-medium justify-center">
        <div className="z-10 px-5 md:w-auto w-full flex justify-between md:hidden">
          <div>
            <div className="text-3xl p-2 bg-primary-500 rounded-full" onClick={() => setOpen(true)}>
              <Unicons.UilListUiAlt color="white" name={`${open ? "close" : "menu"}`} />
            </div>
          </div>
        </div>
        <ul className="md:flex md:flex-wrap p-3 rounded-md hidden items-center gap-2 justify-center">
          <NavLinks />
        </ul>
        {/* Mobile nav */}
        <div
          className={`${
            open ? "left-0" : "left-[-100%]"
          } duration-500 md:hidden bg-[rgba(0,0,0,0.5)] w-full  z-50 fixed top-0 overflow-y-auto bottom-0`}
        >
          <div
            className="
         bg-slate-100 z-50 fixed w-2/3 top-0 overflow-y-auto bottom-0 px-4
        duration-700 py-10
        "
          >
            <div className="flex justify-between items-center">
              <h2 className="text-3xl font-bold">AirBrick Infra</h2>
              <button
                className="bg-primary-500 w-10 h-10 rounded text-white flex justify-center items-center"
                onClick={() => setOpen(false)}
              >
                <Unicons.UilTimes />
              </button>
            </div>

            <div className="py-14">
              <NavLinks />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
