import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import Logo192 from "../assets/logo-full.png";

function Header({ position, bg }) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const history = useNavigate();

  const logoutuser = () => {
    localStorage.removeItem("usersdatatoken");
    history("/");
  };

  const [searchInput, setSearchInput] = useState("");

  const searchHandler = (e) => {
    e.preventDefault();
    history(`/project?mask=${searchInput}`);
    setSearchInput("");
  };
  const token = localStorage.getItem("user");
  return (
    <>
      <header className={`${position} top-0 z-50 ${bg} w-full`}>
        <nav className="px-4 lg:px-6 py-2.5">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <Link to="/home" className="flex items-center">
              <img src={Logo192} className="mr-4 w-[130px]" alt="AirBrick Logo" />
            </Link>
            <div className="flex items-center lg:order-2">
              <button
                onClick={toggleMobileMenu}
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="mobile-menu-2"
                aria-expanded={isMobileMenuOpen}>
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : ""}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <svg
                  className={`w-6 h-6 ${isMobileMenuOpen ? "" : "hidden"}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              className={`grow w-full lg:w-auto justify-between items-center lg:flex lg:order-1 ${
                isMobileMenuOpen ? "" : "hidden"
              }`}
              id="mobile-menu-2">
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:mt-0 text-sm w-full">
                <li className="flex items-center w-full">
                  <form className="w-full md:px-2 lg:px-5" autoComplete="off" onSubmit={searchHandler}>
                    <div className="flex">
                      <div className="relative group">
                        <button
                          id="dropdown-button-2"
                          data-dropdown-toggle="dropdown-search-city"
                          className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm text-center bg-white border border-gray-300 rounded-l-lg "
                          type="button">
                          <Unicons.UilLayerGroup size="19" className="hidden md:block" />
                          <span className="ml-1 mr-3">Categorey</span>
                          <Unicons.UilAngleRightB className="rotate-90" size="18" />
                        </button>
                        <div
                          id="dropdown-search-city"
                          className="absolute top-[85%] z-50 hidden w-full border-t-0 rounded-t-none border border-gray-300 bg-white rounded divide-y divide-gray-100 text-xs group-hover:block"
                          data-popper-reference-hidden
                          data-popper-escaped
                          data-popper-placement="bottom">
                          <ul className="py-1 text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button-2">
                            <li>
                              <Link
                                to="/showcase?tags=open_office"
                                className="inline-flex py-2 px-3 w-full text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                role="menuitem">
                                <div className="inline-flex items-center">Open Office</div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/showcase?tags=private_office"
                                className="inline-flex py-2 px-3 w-full text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                role="menuitem">
                                <div className="inline-flex items-center">Private Office</div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/showcase?tags=boardroom"
                                className="inline-flex py-2 px-3 w-full text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                role="menuitem">
                                <div className="inline-flex items-center">Boardroom</div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/showcase?tags=breakout_space"
                                className="inline-flex py-2 px-3 w-full text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                role="menuitem">
                                <div className="inline-flex items-center">Breakout Space</div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/showcase?tags=pantry_area-coffee_point"
                                className="inline-flex py-2 px-3 w-full text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                role="menuitem">
                                <div className="inline-flex items-center">Pantry</div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="text"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value.toUpperCase())}
                          className="block p-2.5 w-full z-20 text-sm bg-white rounded-r-lg border-l-0 border border-gray-300 focus:border-gray-300 focus:ring-0"
                          placeholder="Search Projects...."
                          required
                          autoComplete="off"
                        />

                        <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm rounded-r-lg">
                          <Unicons.UilSearch size="19" />
                        </button>
                      </div>
                    </div>
                  </form>
                </li>

                <li className="flex items-center">
                  <Link
                    to="/home"
                    className={`block navlinks ${
                      location.pathname === "/home" ? "text-primary-500" : ""
                    } hover hover:text-primary-500 rounded-full flex p-2 gap-1`}
                    aria-current="page">
                    <Unicons.UilHome size="19" />
                    Home
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/showcase"
                    className={`block navlinks ${
                      location.pathname === "/showcase" ? "text-primary-500" : ""
                    } hover hover:text-primary-500 p-2 rounded-full flex gap-1`}>
                    <Unicons.UilClipboardNotes size="19" />
                    Showcase
                  </Link>
                </li>

                <li className="flex items-center">
                  <Link
                    to="/trending"
                    className={`block navlinks ${
                      location.pathname === "/trending" ? "text-primary-500" : ""
                    } hover hover:text-primary-500 p-2 rounded-full flex gap-1`}>
                    <Unicons.UilBookmarkFull size="19" />
                    Trending
                  </Link>
                </li>

                <li className="flex items-center">
                  <Link
                    to="/moodboard"
                    className={`block navlinks ${
                      location.pathname === "/moodboard" ? "text-primary-500" : ""
                    } hover hover:text-primary-500 p-2 rounded-full flex gap-1`}>
                    <Unicons.UilCloudHeart size="19" /> Moodboard
                  </Link>
                </li>

                <li className="flex items-center">
                  <Link
                    to="/project-showcase"
                    className={`block navlinks ${
                      location.pathname === "/project-showcase" ? "text-primary-500" : ""
                    } hover hover:text-primary-500 p-2 rounded-full flex gap-1`}>
                    <Unicons.UilBuilding size="19" /> Projects
                  </Link>
                </li>

                {token === "sb@airbrickinfra.com" && (
                  <li className="flex items-center">
                    <Link
                      to="/user-list"
                      className={`block navlinks ${
                        location.pathname === "/user-list" ? "text-primary-500" : ""
                      } hover hover:text-primary-500 p-2 rounded-full flex gap-1`}>
                      <Unicons.UilUsersAlt size="19" /> Users
                    </Link>
                  </li>
                )}

                <li className="flex items-center">
                  <button
                    onClick={() => logoutuser()}
                    className="text-white bg-primary-500 p-2 rounded-full text-sm md:ml-7">
                    <span>
                      <Unicons.UilSignout size="19" />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
