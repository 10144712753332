import React from "react";
import * as Unicons from "@iconscout/react-unicons";

const RadioBtn = ({ value, name, formData, handleInputChange }) => {
  return (
    <label className="cursor-pointer">
      <input
        type="radio"
        className="peer sr-only"
        value={value}
        name={name}
        checked={formData[name] === value}
        onChange={handleInputChange}
      />
      <div className="w-full rounded-md bg-white p-2 text-gray-600 ring-2 ring-slate-200 transition-all hover:shadow peer-checked:text-primary-500 peer-checked:ring-primary-500 peer-checked:ring-offset-2">
        <div className="flex items-center justify-between gap-5">
          <p className="text-sm font-semibold capitalize">{value}</p>
          <div>{formData[name] === value ? <Unicons.UilCheckCircle /> : <Unicons.UilCircle />}</div>
        </div>
      </div>
    </label>
  );
};

export default RadioBtn;
