import React from "react";
import * as Unicons from "@iconscout/react-unicons";

function Checkbox({ value, register, checkedValues,disable }) {
  return (
    <label className="cursor-pointer">
      <input disabled={disable} type="checkbox" className="peer sr-only" value={value} {...register("direction")} />
      <div className="w-full rounded-md bg-white p-2 text-gray-600 ring-2 ring-slate-200 transition-all hover:shadow peer-checked:text-primary-500 peer-checked:ring-primary-500 peer-checked:ring-offset-2 peer-disabled:bg-gray-300">
        <div className="flex items-center justify-between gap-5">
          <p className="text-sm font-semibold capitalize">{value}</p>
          <div>{checkedValues.length > 0 && checkedValues.includes(value) ? <Unicons.UilCheckSquare /> : <Unicons.UilSquare />}</div>
        </div>
      </div>
    </label>
  );
}

export default Checkbox;
