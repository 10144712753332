import React, { useState, useEffect } from "react";
import Layout from "./../Layout";
import { Link } from "react-router-dom";
import axios from "axios";
import DeleteUserModal from "./../../components/formComponents/DeleteUserModal";
import { UilTrashAlt, UilEditAlt } from "@iconscout/react-unicons";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/user/get-all`, axiosConfig);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error.response ? error.response.data : error);
    }
  };

  const handleDelete = async () => {
    if (!deleteUserId) return;

    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/api/user/delete/${deleteUserId}`, axiosConfig);
      fetchUsers();
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting user:", error.response ? error.response.data : error);
    }
  };

  const openDeleteModal = (userId) => {
    setDeleteUserId(userId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteUserId(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <Layout>
      <div className="bg-white p-3 min-h-[88vh]">
        <div className="w-full max-w-screen-lg mx-auto mt-3">
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-3xl font-semibold">All Users</h1>
            <Link
              to="/add-user"
              className="bg-teal-500 text-white rounded-md hover:bg-teal-600 font-semibold px-4 py-2"
            >
              Add User
            </Link>
          </div>

          <div className="rounded-lg overflow-hidden border">
            <table className="w-full table-fixed">
              <thead>
                <tr className="bg-gray-100">
                  <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Name</th>
                  <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Email</th>
                  <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {users.map((user) => (
                  <tr key={user._id} className={user.email==="utkarsh.mitmeerut@gmail.com" && "hidden"}>
                    <td className="py-4 px-6 border-b border-gray-200">{user.fname}</td>
                    <td className="py-4 px-6 border-b border-gray-200 truncate">{user.email}</td>
                    <td className="py-4 px-6 border-b border-gray-200 space-x-2">
                      <div className="flex items-center gap-3">
                        <Link to={`/edit-user/${user._id}`}>
                          <button className="bg-green-200 text-green-500 py-1 px-2 rounded text-xs">
                            <UilEditAlt />
                          </button>
                        </Link>
                        {user.email !== "sb@airbrickinfra.com" ? (
                          <button
                            onClick={() => openDeleteModal(user._id)}
                            className="bg-red-200 text-red-500 py-1 px-2 rounded text-xs"
                          >
                            <UilTrashAlt />
                          </button>
                        ) : (
                          <span className="py-1.5 px-2 rounded text-sm fonr-bold bg-yellow-200 text-yellow-500">
                            Admin
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Delete User Modal */}
          <DeleteUserModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} onDelete={handleDelete} />
        </div>
      </div>
    </Layout>
  );
};

export default UserList;
