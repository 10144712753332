import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../components/Loader";
import { useAuth } from "./useAuth";

const AuthWrapper = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <Loader />; // You can replace this with a proper loading component
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default AuthWrapper;
