import React from "react";
import { Link } from "react-router-dom";
import Img from "../components/Img";
import serverImg from "../assets/error-server.jpg";

const Error500 = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="text-center">
        <Img className="w-[250px]" src={serverImg} />
        <p className="mt-4 text-3xl text-gray-600 dark:text-gray-400">Oops, something went wrong.</p>
        <p className="mt-2 mb-6 text-sm text-gray-500 dark:text-gray-400">
          Internal Server 500 Error. The server has been deserted for a while. Please be patient or try again later.
        </p>
        <Link to="/" className=" text-white bg-primary-500 rounded-lg py-2 px-6">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Error500;
