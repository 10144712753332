import React, { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import Layout from "../Layout";
import { ToastContainer, toast } from "react-toastify";
import wizardImg1 from "../../assets/step-1.webp";
import wizardImg2 from "../../assets/step-2.webp";
import wizardImg3 from "../../assets/step-3.webp";
import axios from "axios";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFloorPlans } from "../../redux/slicers/selectedFloorPlan";
import UserForm from "./UserForm";
import bgImg from "../../assets/bg-userform.jpg";
import RadioBtn from "../../components/formComponents/RadioBtn";

const Wizard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    industry: "",
    shapeOfOffice: "",
    carpetSize: "",
    officeType: "",
    workStations: "",

    managerCabins: "",
    bossCabins: "",
    meet4s: "",
    meet8s: "",
    conferenceRoom: "",

    reception: "",
    washroom: "",
    breakOut: "",
    foodPlace: "",
  });

  const conditionalOptions_2 = {
    corporate: {
      1000: {
        workStations: [20, 25],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      2000: {
        workStations: [40, 50],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
      3000: {
        workStations: [60, 70],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      4000: {
        workStations: [80, 100],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
      5000: {
        workStations: [100, 125],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      6000: {
        workStations: [120, 150],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
      7000: {
        workStations: [140, 175],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      8000: {
        workStations: [160, 200],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
      9000: {
        workStations: [180, 225],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      10000: {
        workStations: [200, 250],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
    },
    coworking: {
      6000: {
        workStations: [160, 185],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
      7000: {
        workStations: [185, 220],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      8000: {
        workStations: [210, 255],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
      9000: {
        workStations: [235, 280],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2],
        meet4s: [1, 2, 3],
      },
      10000: {
        workStations: [270, 315],
        managerCabins: [1, 2, 3, 4],
        bossCabins: [1, 2, 3, 4],
        meet4s: [1, 2, 3, 4],
      },
    },
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/floor/matched`, formData, axiosConfig);
      dispatch(setFloorPlans(response.data.FP_IDs));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    navigate("/moodboard/plan");
  };

  const prevFormStep = () => {
    setCurrentStep((cur) => cur - 1);
  };

  const FormStep0 = () => {
    setCurrentStep(1);
  };

  const FormStep1 = () => {
    if (
      formData.industry !== "" &&
      formData.shapeOfOffice !== "" &&
      formData.carpetSize !== "" &&
      formData.officeType !== "" &&
      formData.workStations !== ""
    ) {
      setCurrentStep((cur) => cur + 1);
    } else {
      toast.error("All fields required...");
    }
  };

  const FormStep2 = () => {
    setCurrentStep((cur) => cur + 1);
  };

  const FormStep3 = () => {
    setCurrentStep((cur) => cur + 1);
    handleSubmit();
  };

  const renderBtn = () => {
    if (currentStep > 3) {
      return undefined;
    } else if (currentStep === 1) {
      return (
        <div className="flex justify-end mt-8 2xl:mt-20">
          <button
            type="button"
            onClick={FormStep1}
            className="bg-primary-500 py-2 px-7 text-sm rounded-lg text-white flex gap-2 items-center justify-center disabled:bg-gray-600 disabled:cursor-not-allowed"
          >
            Next
            <Unicons.UilArrowRight />
          </button>
        </div>
      );
    } else if (currentStep === 2) {
      return (
        <div className="flex justify-end mt-8 2xl:mt-20">
          <button
            type="button"
            onClick={prevFormStep}
            className="bg-white border-2 border-primary-500 mr-3 py-2 px-7 text-sm rounded-lg text-primary-500 flex gap-2 items-center justify-center hover:bg-primary-500 hover:text-white transition-all ease delay-150 disabled:bg-gray-600 disabled:cursor-not-allowed"
          >
            <Unicons.UilArrowLeft /> Previous
          </button>
          <button
            type="button"
            onClick={FormStep2}
            className="bg-primary-500 py-2 px-7 text-sm rounded-lg text-white flex gap-2 items-center justify-center disabled:bg-gray-600 disabled:cursor-not-allowed"
          >
            Next <Unicons.UilArrowRight />
          </button>
        </div>
      );
    } else if (currentStep === 3) {
      return (
        <div className="flex justify-end mt-8 2xl:mt-20">
          <button
            type="button"
            onClick={prevFormStep}
            className="bg-white border-2 border-primary-500 mr-3 py-2 px-7 text-sm rounded-lg text-primary-500 flex gap-2 items-center justify-center hover:bg-primary-500 hover:text-white transition-all ease delay-150 disabled:bg-gray-600 disabled:cursor-not-allowed"
          >
            <Unicons.UilArrowLeft /> Previous
          </button>
          <button
            type="button"
            onClick={FormStep3}
            className="bg-primary-500 py-2 px-7 text-sm rounded-lg text-white flex gap-2 items-center justify-center disabled:bg-gray-600 disabled:cursor-not-allowed"
          >
            Generate <Unicons.UilArrowRight />
          </button>
        </div>
      );
    }
  };

  const stepBackgrounds = {
    1: `url("${wizardImg1}")`,
    2: `url("${wizardImg2}")`,
    3: `url("${wizardImg3}")`,
  };

  return (
    <Layout>
      {!loading ? (
        <div
          style={{
            background: `linear-gradient(45deg, 
                rgba(0,0,0, 0.5), 
                rgba(0,0,0, 0.5)),url(${bgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="min-h-[90vh] flex items-center justify-center w-full py-10"
        >
          <div className="w-full lg:px-32">
            {currentStep !== 0 ? (
              <div className="container max-w-screen-xl mx-auto bg-white rounded-xl flex overflow-hidden shadow w-full">
                <div
                  className="w-[30%] justify-center flex items-center"
                  style={{
                    backgroundImage: `linear-gradient(90deg, 
                rgba(0,0,0,0.5),rgba(0,0,0,0.5)),${stepBackgrounds[currentStep]}`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="relative before:absolute before:inset-0 before:ml-4 before:-translate-x-px before:h-[calc(100%_-_20px)] before:w-[3px] before:bg-slate-200">
                    <div
                      className={`absolute w-[4px] ${currentStep === 2 && "h-1/2"} ${
                        currentStep === 3 && "h-[calc(100%_-_20px)]"
                      } bg-primary-500 ml-[calc(1rem_-_1.5px)]`}
                    ></div>
                    <div className="relative mb-10">
                      <div className="items-center mb-3">
                        <div className="flex space-x-4">
                          <div
                            className={`${currentStep === 1 && "border-primary-500"} ${
                              currentStep > 1 ? "border-primary-500 bg-primary-500" : "bg-white"
                            } flex items-center justify-center w-8 h-8 rounded-full border-2 shadow`}
                          >
                            {currentStep === 1 ? "1" : <Unicons.UilCheck color="white" />}
                          </div>
                          <div>
                            <p className="font-bold text-lg text-white md:w-28">Step 1</p>
                            <div className="text-white text-sm">Understanding Layout</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative mb-10">
                      <div className="items-center mb-3">
                        <div className="flex items-center space-x-4">
                          <div
                            className={`${currentStep === 2 && "border-primary-500"} ${
                              currentStep > 2 ? "border-primary-500 bg-primary-500" : "bg-white"
                            } flex items-center justify-center w-8 h-8 rounded-full border-2 shadow`}
                          >
                            {currentStep <= 2 ? "2" : <Unicons.UilCheck color="white" />}
                          </div>

                          <div>
                            <p className="font-bold text-lg text-white md:w-28">Step 2</p>
                            <div className="text-white text-sm">Area Types</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative mt-10">
                      <div className=" items-center mb-3">
                        <div className="flex items-center space-x-4">
                          <div
                            className={`${currentStep === 3 && "border-primary-500"} ${
                              currentStep > 3 ? "border-primary-500 bg-primary-500" : "bg-white"
                            } flex items-center justify-center w-8 h-8 rounded-full border-2 shadow`}
                          >
                            {currentStep <= 3 ? "3" : <Unicons.UilCheck color="white" />}
                          </div>

                          <div>
                            <p className="font-bold text-lg text-white md:w-28">Step 3</p>
                            <div className="text-white text-sm">Supporting Areas</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[70%] p-6 bg-slate-50">
                  <form>
                    {currentStep >= 1 && (
                      <div className={currentStep === 1 ? "block" : "hidden"}>
                        <h2 className="text-xl font-bold mb-10 2xl:mb-24 2xl:text-3xl">
                          Step 1 - Understanding Layout
                        </h2>
                        <div className="container mx-auto space-y-4">
                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Industry</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-2 gap-5">
                                <RadioBtn
                                  formData={formData}
                                  name="industry"
                                  value="coworking"
                                  handleInputChange={handleInputChange}
                                />
                                <RadioBtn
                                  formData={formData}
                                  name="industry"
                                  value="corporate"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Shape of Office</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-3 gap-5 w-full">
                                <RadioBtn
                                  formData={formData}
                                  name="shapeOfOffice"
                                  value="rectangle"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="shapeOfOffice"
                                  value="square"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="shapeOfOffice"
                                  value="l shape"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Carpet size</h3>
                            </div>
                            <div className="w-[70%]">
                              <select
                                name="carpetSize"
                                value={formData.carpetSize}
                                onChange={handleInputChange}
                                className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Choose an option</option>
                                {formData.industry &&
                                  Object.keys(conditionalOptions_2[formData.industry]).map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Type</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-3 gap-5 w-full">
                                <RadioBtn
                                  formData={formData}
                                  name="officeType"
                                  value="open"
                                  handleInputChange={handleInputChange}
                                />
                                {formData.industry == "coworking" ? (
                                  <>
                                    <RadioBtn
                                      formData={formData}
                                      name="officeType"
                                      value="suite"
                                      handleInputChange={handleInputChange}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <RadioBtn
                                      formData={formData}
                                      name="officeType"
                                      value="closed"
                                      handleInputChange={handleInputChange}
                                    />
                                    <RadioBtn
                                      formData={formData}
                                      name="officeType"
                                      value="mixed"
                                      handleInputChange={handleInputChange}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">WorkStations</h3>
                            </div>
                            <div className="w-[70%]">
                              <select
                                name="workStations"
                                value={formData.workStations}
                                onChange={handleInputChange}
                                className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Choose an option</option>
                                {formData.carpetSize &&
                                  conditionalOptions_2[formData.industry]?.[formData.carpetSize]?.workStations.map(
                                    (option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {currentStep >= 2 && (
                      <div className={currentStep === 2 ? "block" : "hidden"}>
                        <h2 className="text-xl font-bold mb-10 2xl:mb-24 2xl:text-3xl">Step 2 - Area Types</h2>
                        <div className="container mx-auto space-y-4">
                          <div className="flex  items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Manager Cabins</h3>
                            </div>
                            <div className="w-[70%]">
                              <select
                                name="managerCabins"
                                value={formData.managerCabins}
                                onChange={handleInputChange}
                                className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Choose an option</option>
                                {formData.carpetSize &&
                                  conditionalOptions_2[formData.industry]?.[formData.carpetSize]?.managerCabins.map(
                                    (option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="flex  items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">MD/Boss Cabins</h3>
                            </div>
                            <div className="w-[70%]">
                              <select
                                name="bossCabins"
                                value={formData.bossCabins}
                                onChange={handleInputChange}
                                className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Choose an option</option>
                                {formData.carpetSize &&
                                  conditionalOptions_2[formData.industry]?.[formData.carpetSize]?.bossCabins.map(
                                    (option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="flex  items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">4 Seater Meetings</h3>
                            </div>
                            <div className="w-[70%]">
                              <select
                                name="meet4s"
                                value={formData.meet4s}
                                onChange={handleInputChange}
                                className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Choose an option</option>
                                {formData.carpetSize &&
                                  conditionalOptions_2[formData.industry]?.[formData.carpetSize]?.meet4s.map(
                                    (option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>

                          <div className="flex  items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">8 Seater Meetings</h3>
                            </div>
                            <div className="w-[70%]">
                              <select
                                name="meet8s"
                                value={formData.meet8s}
                                onChange={handleInputChange}
                                className="bg-white border-2 border-slate-200 text-gray-500 font-semibold capitalize text-sm rounded-lg focus:ring-0 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Choose an option</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Conference Room</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-2 gap-5">
                                <RadioBtn
                                  formData={formData}
                                  name="conferenceRoom"
                                  value="yes"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="conferenceRoom"
                                  value="no"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {currentStep >= 3 && (
                      <div className={currentStep === 3 ? "block" : "hidden"}>
                        <h2 className="text-xl font-bold mb-10 2xl:mb-24 2xl:text-3xl">Step 3 - Supporting Areas</h2>
                        <div className="container mx-auto space-y-4">
                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Reception</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-2 gap-5">
                                <RadioBtn
                                  formData={formData}
                                  name="reception"
                                  value="yes"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="reception"
                                  value="no"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Washroom</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-3 gap-5 w-full">
                                <RadioBtn
                                  formData={formData}
                                  name="washroom"
                                  value="single"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="washroom"
                                  value="separate"
                                  handleInputChange={handleInputChange}
                                />
                                <RadioBtn
                                  formData={formData}
                                  name="washroom"
                                  value="NA"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Food Place</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-2 gap-5">
                                <RadioBtn
                                  formData={formData}
                                  name="foodPlace"
                                  value="pantry"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="foodPlace"
                                  value="cafeteria"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="w-[30%]">
                              <h3 className="text-lg font-bold">Breakout/Lounge Area</h3>
                            </div>
                            <div className="w-[70%]">
                              <div className="grid grid-cols-3 gap-5 w-full">
                                <RadioBtn
                                  formData={formData}
                                  name="breakOut"
                                  value="100 sqft"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="breakOut"
                                  value="200 sqft"
                                  handleInputChange={handleInputChange}
                                />

                                <RadioBtn
                                  formData={formData}
                                  name="breakOut"
                                  value="NA"
                                  handleInputChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {renderBtn()}
                  </form>
                </div>
              </div>
            ) : (
              <UserForm handleFunction={FormStep0} />
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

export default Wizard;
