import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "../../assets/logo-full.png";
import legend from "../../assets/Legend.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    textAlign: "center",
    padding: "10px",
    paddingBottom: "0px",
    textTransform: "capitalize",
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
  },
  floorSection: {
    width: "40%",
  },
  mapFloorSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  mapContainer: {
    width: "60%",
    height: "17.7cm",
    overflow: "hidden",
  },
  mapSection: {
    display: "flex",
    flexDirection: "row",
    gap: "3px",
    marginBottom: "3px",
  },
  stcolumn: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    flex: "1",
  },
  furnitureSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "4cm",
    overflow: "hidden",
    marginBottom: "15px",
  },
  extfurnitureSection: {
    display: "flex",
    flexDirection: "row",
    gap: "3px",
    height: "5cm",
    overflow: "hidden",
  },
  furnitureColumn: {
    width: "25%",
  },
  mapImg: {
    width: "100%",
  },
  txtFirst: {
    marginBottom: "5px",
    fontSize: "10px",
    width: "50%",
    textAlign: "left",
  },
  txtSec: {
    marginBottom: "5px",
    fontSize: "10px",
    width: "33.33%",
    textAlign: "left",
  },
  smText: {
    fontSize: "10px",
    opacity: "0.8",
  },
});

const Pdf = ({ floorData, fpImg, furnitureData, userData, extFurnitureData, uploadedPlan }) => {
  const currentDate = new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const year = currentDate.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  console.log(floorData);

  const renderColumns = () => {
    const columns = [];
    const filteredMaps = floorData?.maps.filter((map) => map.img) || [];
    const totalImages = filteredMaps.length;
    const columnsCount =
      totalImages <= 0
        ? 0
        : totalImages <= 6
        ? 2
        : totalImages <= 9
        ? 3
        : totalImages <= 12
        ? 4
        : totalImages <= 20
        ? 5
        : 6;
    const imagesPerColumn = Math.ceil(totalImages / columnsCount);

    for (let i = 0; i < columnsCount; i++) {
      const startIndex = i * imagesPerColumn;
      const endIndex = Math.min((i + 1) * imagesPerColumn, totalImages);
      const columnImages = filteredMaps.slice(startIndex, endIndex);

      columns.push(
        <div key={i} style={styles.stcolumn}>
          {columnImages.map((imageData, index) => (
            <div key={index}>
              <Image src={`https://static.airbrickinfra.com/brick-img/${imageData?.img}.jpg`} style={styles.mapImg} />
              <Text style={styles.smText}>{imageData?.img}</Text>
            </div>
          ))}
        </div>,
      );
    }

    return columns;
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={{ position: "absolute", top: "25%", left: "2%" }}>
          <Text
            style={{
              opacity: "0.1",
              color: "#008080",
              fontSize: "140px",
              fontWeight: "extrabold",
              transform: "rotate(-25deg)",
            }}
          >
            AirBrick Infra
          </Text>
        </View>
        <View style={styles.topSection}>
          <div style={styles.mapContainer}>
            <div style={styles.mapSection}>{renderColumns()}</div>
            <div style={styles.extfurnitureSection}>
              {extFurnitureData?.map((imageData, index) => (
                <div key={index} style={styles.furnitureColumn}>
                  <Image src={`https://static.airbrickinfra.com/brick-img/${imageData}.jpg`} style={styles.mapImg} />
                  <Text style={styles.smText}>{imageData}</Text>
                </div>
              ))}
            </div>
          </div>

          <div style={styles.floorSection}>
            <div style={styles.furnitureSection}>
              {furnitureData?.map((imageData, index) => (
                <div key={index} style={styles.furnitureColumn}>
                  <Image src={`https://static.airbrickinfra.com/furniture/${imageData}.jpg`} style={styles.mapImg} />
                  <Text style={styles.smText}>{imageData}</Text>
                </div>
              ))}
            </div>
            <View style={{ border: "1.4px solid #ccc" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "8cm",
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <Image style={{ height: "100%" }} src={uploadedPlan.path || fpImg} />
              </div>
            </View>

            <View
              style={{
                fontSize: "13px",
                marginTop: "4px",
                paddingTop: "5px",
                paddingLeft: "5px",
                textTransform: "unset",
                border: "1.4px solid #ccc",
                textAlign: "left",
                height: "5cm",
              }}
            >
              <Text>Notes</Text>
            </View>
          </div>
        </View>
        <View>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "white",
              borderTop: "1px solid #000",
              borderBottom: "1px solid #000",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "50%" }}>
              <div style={{ width: "30%" }}>
                <Image style={{ width: "100%" }} src={logo} alt="Logo" />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "70%",
                  padding: "5px",
                  borderLeft: "1px solid #000",
                  borderRight: "1px solid #000",
                }}
              >
                <Text style={styles.txtFirst}>Company - {userData?.company}</Text>
                <Text style={styles.txtFirst}>Direction - {userData?.direction}</Text>
                <Text style={styles.txtFirst}>Location - {userData?.location}</Text>
                <Text style={styles.txtFirst}>Contact - {userData?.number}</Text>
                <Text style={styles.txtFirst}>Date - {formattedDate}</Text>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
              <div style={{ width: "80%" }}>
                <Image style={{ width: "100%" }} src={legend} alt="Legend" />
              </div>

              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {userData.direction.length > 0 && (
                  <Image
                    style={{ width: "50px" }}
                    src={`https://static.airbrickinfra.com/direction/${userData.direction.join("")}.png`}
                    alt="Direction"
                  />
                )}
              </div>
            </div>
          </div>
        </View>
        <View>
          <Text style={{ fontSize: "10px", marginTop: "5px" }}>
            This Drawing is a sole property of AIRBRICK INFRA & cannot be used/copied/ reproduced without company's
            permission
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
