import { useState } from "react";

export const BeforeAfter = ({ data }) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const handleMove = (event) => {
    if (!isDragging) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const clientX = event.type.includes("mouse") ? event.clientX : event.touches[0].clientX;
    const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
    const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));

    setSliderPosition(percent);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
    handleMove(event);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="w-full relative"
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
    >
      {data && data.length > 0 && (
        <div
          className="relative w-full h-[300px] md:h-[700px] aspect-[70/45] m-auto overflow-hidden select-none"
          onMouseMove={handleMove}
          onMouseDown={handleMouseDown}
          onTouchMove={handleMove}
          onTouchStart={handleTouchStart}
        >
          <img alt="" className="w-full h-[300px] md:h-[700px]" src={`${process.env.REACT_APP_BACK_URL}/api/minio/${data[0]}`} />

          <div
            className="absolute top-0 left-0 right-0 w-full m-auto overflow-hidden select-none"
            style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
          >
            <img alt="" className="w-full h-[300px] md:h-[700px]" src={`${process.env.REACT_APP_BACK_URL}/api/minio/${data[1]}`} />
          </div>
          <div
            className="absolute top-0 bottom-0 w-1 bg-primary-500 cursor-ew-resize"
            style={{
              left: `calc(${sliderPosition}% - 1px)`,
            }}
          >
            <div className="bg-primary-500 absolute rounded-full h-3 w-3 -left-1 top-[calc(50%-5px)]" />
          </div>
        </div>
      )}
    </div>
  );
};
