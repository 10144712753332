import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ImageMapper from "react-img-mapper";
import axios from "axios";
import * as Unicons from "@iconscout/react-unicons";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import NoResultsComponent from "./../../components/NoResultsComponent";
import { PDFViewer } from "@react-pdf/renderer";
import Pdf from "./Pdf";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import Img from "../../components/Img";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const SelfMoodBoard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  var { floorId } = useParams();
  const [chooseImages, setChooseImages] = useState([]);
  const [popup, setPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [imageArea, setImageArea] = useState();
  const [floorPlan, setFloorPlan] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [mainTag, setMainTag] = useState("");
  const [pdfView, setPdfView] = useState(false);

  const [furniture, setFurniture] = useState([]);
  const [furniturePopup, setFurniturePopup] = useState(false);
  const [chooseFurniture, setChooseFurniture] = useState([]);
  const [furnitureTag, setFurnitureTag] = useState("");
  const [furniturepage, setFurniturePage] = useState(1);
  const [hasFurnitureMore, setHasFurnitureMore] = useState(true);
  const [selectedFurnitureOption, setSelectedFurnitureOption] = useState("");

  const [extraFurniture, setExtraFurniture] = useState([]);

  const token = localStorage.getItem("usersdatatoken");
  const axiosConfig = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  const moodBoardUserAPI = useSelector((state) => state.moodBoardUser.moodBoardUser);

  const URL = `${process.env.REACT_APP_BACK_URL}/api/minio/${floorPlan.imageUrl}`;
  const MAP = {
    name: "my-map",
    areas: floorPlan?.maps || "loading...",
  };

  const getFloorPlan = async () => {
    setLoading(true);
    try {
      const floorResponse = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACK_URL}/api/floor/self-floorplan/${floorId}`, axiosConfig), // Join selectedTags to create a single string
      ]);
      setFloorPlan(floorResponse[0].data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getFloorPlan();
  }, []);

  const openImagePopup = (area) => {
    fetchImages(area.Tag);
    setImageArea(area);
    setPopup(true);
    setMainTag(area.Tag);
  };

  const openFurniturePopup = (fTag) => {
    fetchFurniture(fTag);
    setFurniturePopup(true);
    setFurnitureTag(fTag);
  };

  const closeImagePopup = () => {
    const substrings = ["Glass", "Table", "Lights"];
    const containsSubstring = substrings.some((substring) => selectedOption.includes(substring));
    if (containsSubstring) {
      const selectedFurnitureOptionCategory = selectedOption.split("/")[0];

      // Check if an item with the same category already exists
      const updatedFurniture = extraFurniture.map((item) => {
        const currentItemCategory = item.split("/")[0];

        // If the category matches, replace the item
        if (currentItemCategory === selectedFurnitureOptionCategory) {
          return selectedOption;
        }

        return item;
      });

      // If no matching category found, push the new item
      if (!updatedFurniture.includes(selectedOption)) {
        updatedFurniture.push(selectedOption);
      }

      setExtraFurniture(updatedFurniture);

      setPopup(false);
      setSelectedOption("");
      setChooseImages([]);
      setHasMore(true);
      setPage(1);
    } else {
      const obj1 = selectedOption
        ? { img: selectedOption, preFillColor: "rgba(0,0,0,0.6)" }
        : { strokeColor: "rgba(0,0,0,0)", preFillColor: "rgba(0,0,0,0)" };

      const updatedMaps = floorPlan?.maps?.map((item) => (item.id === imageArea.id ? { ...item, ...obj1 } : item));

      setFloorPlan({ ...floorPlan, maps: updatedMaps });

      setPopup(false);
      setSelectedOption("");
      setChooseImages([]);
      setHasMore(true);
      setPage(1);
    }
  };

  const closeFurniturePopup = () => {
    if (selectedFurnitureOption) {
      const selectedFurnitureOptionCategory = selectedFurnitureOption.split("/")[0];

      // Check if an item with the same category already exists
      const updatedFurniture = furniture.map((item) => {
        const currentItemCategory = item.split("/")[0];

        // If the category matches, replace the item
        if (currentItemCategory === selectedFurnitureOptionCategory) {
          return selectedFurnitureOption;
        }

        return item;
      });

      // If no matching category found, push the new item
      if (!updatedFurniture.includes(selectedFurnitureOption)) {
        updatedFurniture.push(selectedFurnitureOption);
      }

      setFurniture(updatedFurniture);
    }

    setFurniturePopup(false);
    setSelectedFurnitureOption("");
    setChooseFurniture([]);
    setHasFurnitureMore(true);
    setFurniturePage(1);
  };

  const fetchImages = async (tag) => {
    try {
      if (tag || mainTag) {
        const response = await fetch(
          `${process.env.REACT_APP_BACK_URL}/api/image/filter?tags=${tag || mainTag}&limit=50&page=${page}`,
          axiosConfig,
        );
        const data = await response.json();
        if (data.images.length === 0) {
          setHasMore(false);
        } else {
          setChooseImages((prevImages) => [...prevImages, ...data.images]);
          setPage(page + 1);
        }
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchFurniture = async (tag) => {
    try {
      if (tag || furnitureTag) {
        const response = await fetch(
          `${process.env.REACT_APP_BACK_URL}/api/furniture/filter?tags=${
            tag || furnitureTag
          }&limit=10&page=${furniturepage}`,
          axiosConfig,
        );
        const data = await response.json();
        if (data?.furniture.length === 0) {
          setHasFurnitureMore(false);
        } else {
          setChooseFurniture((prevImages) => [...prevImages, ...data.furniture]);
          setFurniturePage(furniturepage + 1);
        }
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const deleteFloorImg = (item) => {
    const substrings = ["Glass", "Table", "Lights"];
    const containsSubstring = substrings.some((substring) => selectedOption.includes(substring));

    if (containsSubstring) {
      const updatedFurniture = extraFurniture?.filter((map) => map !== item);
      setExtraFurniture(updatedFurniture);
    } else {
      const updatedMaps = floorPlan?.maps?.map((map) => {
        if (map.id === item.id) {
          // Remove 'img' and 'preFillColor' keys from the current map
          const obj1 = { strokeColor: "rgba(0,0,0,0)", preFillColor: "rgba(0,0,0,0)", fillColor: "rgba(0,0,0,0)" };
          delete map.img;
          return { ...item, ...obj1 };
        }
        return map;
      });

      // Update the floor plan with the modified array
      setFloorPlan({ ...floorPlan, maps: updatedMaps });
    }
  };

  const deleteFurnitureImg = (item) => {
    const updatedFurniture = furniture?.filter((map) => map !== item);
    setFurniture(updatedFurniture);
  };

  const [planFile, setPlanFile] = useState({
    name: null,
    path: null,
  });

  const handlePlanFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPlanFile({
          ...planFile,
          name: file.name,
          path: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Layout>
      {!loading ? (
        <>
          {floorPlan?.maps?.length > 0 ? (
            <div className="px-4 pb-10">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex mb-2 items-center justify-center w-7 h-7 rounded-full bg-primary-500 cursor-pointer text-white"
              >
                <Unicons.UilArrowLeft />
              </button>
              <div className="container mx-auto flex gap-x-4">
                <div className="py-4 map-box flex flex-col justify-center items-center bg-slate-100 md:w-[58%] gap-3 rounded-lg">
                  <ImageMapper
                    src={URL}
                    map={MAP}
                    stayMultiHighlighted
                    onClick={openImagePopup}
                    responsive={true}
                    parentWidth={680}
                  />

                  <div className="flex px-3.5">
                    <div className="flex flex-wrap gap-3">
                      <button
                        onClick={() => openFurniturePopup("chair")}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Chairs
                      </button>

                      <button
                        onClick={() => openFurniturePopup("lounge_chair")}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Lounge Chairs
                      </button>

                      <button
                        onClick={() => openFurniturePopup("pouffes")}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Pouffes
                      </button>

                      <button
                        onClick={() => openFurniturePopup("sofa")}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Sofa
                      </button>

                      <button
                        onClick={() => openImagePopup({ Tag: "glass" })}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Glass
                      </button>

                      <button
                        onClick={() => openImagePopup({ Tag: "table" })}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Table
                      </button>
                      <button
                        onClick={() => openImagePopup({ Tag: "lights" })}
                        className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Lights
                      </button>
                    </div>
                    <div className="flex flex-col gap-3">
                      {planFile.name ? (
                        <button className="inline-flex whitespace-nowrap gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white">
                          <span className="truncate w-[100px]">{planFile.name}</span>
                          <Unicons.UilTrashAlt onClick={() => setPlanFile({ name: null, path: null })} size={20} />
                        </button>
                      ) : (
                        <label className="inline-flex gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white items-center">
                          Upload FloorPlan
                          <Unicons.UilImageUpload size={20} />
                          <input
                            type="file"
                            id="floorpalnImgUpload"
                            className="hidden"
                            accept="image/*"
                            onChange={handlePlanFileChange}
                          />
                        </label>
                      )}

                      <button
                        onClick={() => setPdfView(true)}
                        className="inline-flex whitespace-nowrap gap-2 py-2 px-7 rounded-md bg-primary-500 cursor-pointer font-medium text-base text-white"
                      >
                        Create FloorPlan <Unicons.UilFileCheckAlt />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-[42%] bg-slate-100 p-3 rounded-lg h-100 max-h-[100vh] overflow-y-scroll no-scrollbar">
                  <div className="columns-3 gap-2 space-y-2">
                    {floorPlan?.maps && (
                      <>
                        {floorPlan?.maps?.map(
                          (item, index) =>
                            item.img && (
                              <div key={index} className="relative">
                                <Img
                                  className="w-full rounded-lg object-cover"
                                  src={`https://static.airbrickinfra.com/brick-img/${item.img}.jpg`}
                                />
                                <span
                                  onClick={() => deleteFloorImg(item)}
                                  className="absolute z-10 top-1 right-1 bg-slate-100 rounded-md p-1 cursor-pointer hover:bg-slate-200"
                                >
                                  <Unicons.UilTrashAlt size={20} />
                                </span>
                              </div>
                            ),
                        )}
                      </>
                    )}

                    <>
                      {furniture?.map((item, index) => (
                        <div key={index} className="relative">
                          <img
                            className="w-full rounded-lg object-cover"
                            src={`https://static.airbrickinfra.com/furniture/${item}.jpg`}
                          />
                          <span
                            onClick={() => deleteFurnitureImg(item)}
                            className="absolute z-10 top-1 right-1 bg-slate-100 rounded-md p-1 cursor-pointer hover:bg-slate-200"
                          >
                            <Unicons.UilTrashAlt size={20} />
                          </span>
                        </div>
                      ))}
                    </>
                    <>
                      {extraFurniture?.map((item, index) => (
                        <div key={index} className="relative">
                          <img
                            className="w-full rounded-lg object-cover"
                            src={`https://static.airbrickinfra.com/brick-img/${item}.jpg`}
                          />
                          <span
                            onClick={() => deleteFloorImg(item)}
                            className="absolute z-10 top-1 right-1 bg-slate-100 rounded-md p-1 cursor-pointer hover:bg-slate-200"
                          >
                            <Unicons.UilTrashAlt size={20} />
                          </span>
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>

              {popup && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center pop-backdrop"
                  onClick={closeImagePopup}
                >
                  <div className="fixed inset-0 overflow-auto no-scrollbar">
                    <div className="flex items-center justify-center min-h-screen px-4">
                      <div
                        className="relative w-full max-w-5xl mx-auto rounded-lg shadow bg-white dark:bg-gray-700"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* Modal header */}
                        <div className="flex items-start justify-between px-4 rounded-t ">
                          <button
                            onClick={closeImagePopup}
                            type="button"
                            className="absolute top-3 right-3 sm:-top-2 sm:-right-2 z-50 bg-[#efefef] hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                          >
                            <Unicons.UilTimes />
                          </button>
                        </div>
                        {/* Modal body */}
                        <div className="p-5 min-h-[70vh]">
                          <InfiniteScroll
                            dataLength={chooseImages.length} // This is important field to render the next data
                            next={fetchImages}
                            className="no-scrollbar"
                            height={"80vh"}
                            hasMore={hasMore} // Use the state variable to control if there's more data to load
                            loader={
                              <div className="flex justify-center gap-x-2 font-bold text-lg">
                                <Unicons.UilSync className="animate-spin" color="#008080" /> Loading...
                              </div>
                            }
                            endMessage={<p className="w-full text-center">Yay! You have seen it all</p>}
                          >
                            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                              <Masonry gutter="1rem">
                                {chooseImages.map((option, index) => (
                                  <div key={index} className="relative mb-2">
                                    <input
                                      type="radio"
                                      id={option.ID}
                                      name="radio-group"
                                      value={`${option.Mask}/${option.ID}`}
                                      checked={selectedOption === `${option.Mask}/${option.ID}`}
                                      onChange={() => setSelectedOption(`${option.Mask}/${option.ID}`)}
                                      className="hidden"
                                    />
                                    <label htmlFor={option.ID} className="w-full cursor-pointer rounded-lg">
                                      <Img
                                        src={`https://static.airbrickinfra.com/brick-img/${option?.Mask}/${option?.ID}.jpg`}
                                        alt={option.ID}
                                        className="rounded-xl"
                                      />
                                      {selectedOption === `${option.Mask}/${option.ID}` && (
                                        <div className="absolute top-[30px] left-[10px] z-10 bg-green-400 rounded-full flex items-center justify-center text-white">
                                          <Unicons.UilCheckCircle />
                                        </div>
                                      )}
                                    </label>
                                  </div>
                                ))}
                              </Masonry>
                            </ResponsiveMasonry>
                          </InfiniteScroll>
                        </div>
                        {/* Modal footer */}
                        <div className="flex items-center justify-end p-5 pt-1 rounded-b gap-3">
                          <button
                            type="button"
                            onClick={closeImagePopup}
                            className="text-white text-sm bg-green-500 hover:bg-green-600 py-2 px-8 rounded-lg"
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {furniturePopup && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center pop-backdrop"
                  onClick={closeFurniturePopup}
                >
                  <div className="fixed inset-0">
                    <div className="flex items-center justify-center min-h-screen px-4">
                      <div
                        className="relative w-full max-w-5xl mx-auto rounded-lg shadow bg-white dark:bg-gray-700"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* Modal header */}
                        <div className="flex items-start justify-between px-4 rounded-t ">
                          <button
                            onClick={closeFurniturePopup}
                            type="button"
                            className="absolute top-3 right-3 sm:-top-2 sm:-right-2 z-50 bg-[#efefef] hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                          >
                            <Unicons.UilTimes />
                          </button>
                        </div>
                        {/* Modal body */}
                        <div className="p-5 min-h-[70vh]">
                          <InfiniteScroll
                            dataLength={chooseFurniture.length} // This is important field to render the next data
                            next={fetchFurniture}
                            height={"80vh"}
                            className="no-scrollbar"
                            hasMore={hasFurnitureMore} // Use the state variable to control if there's more data to load
                            loader={
                              <div className="flex justify-center gap-x-2 font-bold text-lg">
                                <Unicons.UilSync className="animate-spin" color="#008080" /> Loading...
                              </div>
                            }
                            endMessage={<p className="w-full text-center">Yay! You have seen it all</p>}
                          >
                            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 950: 3, 1100: 4 }}>
                              <Masonry gutter={"1.2rem"}>
                                {chooseFurniture?.map((option, index) => (
                                  <div key={index} className="relative mb-2">
                                    <input
                                      type="radio"
                                      id={option.ID}
                                      name="radio-group"
                                      value={`${option.Mask}/${option.ID}`}
                                      checked={selectedFurnitureOption === `${option.Mask}/${option.ID}`}
                                      onChange={() => setSelectedFurnitureOption(`${option.Mask}/${option.ID}`)}
                                      className="hidden"
                                    />
                                    <label htmlFor={option.ID} className="w-full cursor-pointer">
                                      <img
                                        src={`https://static.airbrickinfra.com/furniture/${option?.Mask}/${option?.ID}.jpg`}
                                        alt={option.ID}
                                        className="rounded-xl border-2 shadow-lg w-full"
                                      />
                                      {selectedFurnitureOption === `${option.Mask}/${option.ID}` && (
                                        <div className="absolute top-[30px] left-[10px] z-10 bg-green-400 rounded-full flex items-center justify-center text-white">
                                          <Unicons.UilCheckCircle />
                                        </div>
                                      )}
                                    </label>
                                  </div>
                                ))}
                              </Masonry>
                            </ResponsiveMasonry>
                          </InfiniteScroll>
                        </div>
                        {/* Modal footer */}
                        <div className="flex items-center justify-end p-5 pt-1 rounded-b gap-3">
                          <button
                            type="button"
                            onClick={closeFurniturePopup}
                            className="text-white text-sm bg-green-500 hover:bg-green-600 py-2 px-8 rounded-lg"
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {pdfView && floorPlan._id && (
                <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[99999] h-screen w-screen bg-gradient-to-r from-[rgba(0,0,0,0.5)] to-[rgba(0,0,0,0.5)]">
                  <button
                    className="absolute top-2 right-7 bg-white p-2 rounded-full"
                    onClick={() => setPdfView(false)}
                  >
                    <Unicons.UilTimes size={25} />
                  </button>
                  <PDFViewer className="mx-auto" width="80%" height="100%">
                    <Pdf
                      floorData={floorPlan}
                      fpImg={`${process.env.REACT_APP_BACK_URL}/api/minio/${floorPlan?.imageUrl}`}
                      extFurnitureData={extraFurniture}
                      furnitureData={furniture}
                      userData={moodBoardUserAPI}
                      uploadedPlan={planFile}
                    />
                  </PDFViewer>
                </div>
              )}
            </div>
          ) : (
            <NoResultsComponent />
          )}
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

export default SelfMoodBoard;
