import PropTypes from "prop-types";
import ReactSlider from "react-slider";
import cx from "classnames";

const MultiRangeSlider = ({ min, max, value, onChange }) => {
  return (
    <div className="mb-20">
      <ReactSlider
        className="mt-14"
        min={min}
        max={max}
        value={value}
        onChange={(value) => onChange(value)}
        renderThumb={(props, state) => (
          <div
            {...props}
            style={{ ...props.style, zIndex: 20 }}
            className="relative flex flex-col items-center w-8 h-8 -mt-3 outline-none">
            <div className="absolute top-0 inline-block px-2 py-1 mb-2 -mt-8 text-xs text-white bg-gray-900 rounded-lg whitespace-nowrap">
              {(state.valueNow / 1000).toFixed(1)} K
              <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full -z-10">
                <div className="w-6 h-6 bg-gray-900 rounded-sm absolute left-1/2 transform -translate-x-1/2 rotate-45"></div>
                <div className="w-6 h-6 bg-white absolute left-1/2 transform -translate-x-1/2 -translate-y-1 rotate-45"></div>
              </div>
            </div>

            <div className="w-8 h-8 bg-white border-4 border-teal-500 rounded-full shadow-lg cursor-pointer" />
          </div>
        )}
        pearling
        minDistance={2000}
        renderTrack={(props, state) => (
          <div
            {...props}
            className={cx("h-2 rounded-full cursor-pointer bg-gray-200", {
              "bg-teal-500 z-10": state.index === 1,
            })}
          />
        )}
      />
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
