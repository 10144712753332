import React, { useEffect, useRef, useState } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ProjectGallery = ({ sliderImages }) => {
  const galleryRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState([]);

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: galleryRef.current,
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
    };
  }, []);

  useEffect(() => {
    const fetchImageDimensions = async () => {
      const dimensions = await Promise.all(
        sliderImages.map(async (item) => {
          try {
            const image = new Image();
            image.src = `${process.env.REACT_APP_BACK_URL}/api/minio/${item}`;
            await image.decode();
            return { src: item, width: image.width, height: image.height };
          } catch (error) {
            console.error("Error fetching image dimensions:", error);
            return { src: item, width: 0, height: 0 };
          }
        }),
      );
      setImageDimensions(dimensions);
    };

    fetchImageDimensions();
  }, [sliderImages]);
  return (
    <div ref={galleryRef} className="relative mb-5">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 950: 3, 1400: 4 }}>
        <Masonry gutter={"1.2rem"}>
          {imageDimensions.map((item, idx) => (
            <a
              key={idx}
              target="_blank"
              href={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.src}`}
              data-pswp-width={item.width}
              data-pswp-height={item.height}
              className="relative rounded-md sm:rounded-xl cursor-pointer">
              <img
                className="rounded-md sm:rounded-xl"
                src={`${process.env.REACT_APP_BACK_URL}/api/minio/${item.src}`}
                alt="Main project"
                width={item.width}
                height={item.height}
              />
            </a>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default ProjectGallery;
