import { combineReducers, configureStore } from "@reduxjs/toolkit";
import floorPlanReducer from "./slicers/selectedFloorPlan";
import  moodBoardUserReducer from "./slicers/moodBoardUser";

const rootReducer = combineReducers({
  floorPlan: floorPlanReducer,
  moodBoardUser: moodBoardUserReducer,
});

const store = configureStore({ reducer: rootReducer });
export default store;
