import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-800">
      <div className="text-center">
        <h1 className="text-9xl font-semibold text-gray-700 dark:text-gray-300">404</h1>
        <p className="mt-4 text-3xl text-gray-600 dark:text-gray-400">Page not found</p>
        <p className="mt-2 mb-6 text-sm text-gray-500 dark:text-gray-400">
          The page you are looking for might be removed or is temporarily unavailable.
        </p>
        <Link to="/" className=" text-white bg-primary-500 rounded-lg py-2 px-6">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
