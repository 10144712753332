import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./../components/Navbar/Navbar";
import { links } from "../components/Navbar/Mylinks";
import { Link, useNavigate } from "react-router-dom";
import HomeLayout from "../components/HomeLayout";
import axios from "axios";

const Home = () => {
  const [bg, setBg] = useState("");

  const fetchProjects = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/image/random-img`);
    setBg(`https://static.airbrickinfra.com/brick-img/${response.data.randomImg}.jpg`);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <HomeLayout>
      <div
        style={{
          background: `linear-gradient(45deg, 
                rgba(0,0,0, 0.2), 
                rgba(0,0,0, 0.2)),url(${bg}) no-repeat center`,
        }}
        className="h-[60vh] lg:h-[80vh] flex items-center justify-center"
      >
        <h1 className="container px-3 lg:px-10 text-5xl lg:text-8xl font-bold text-center text-[#fafafa]">
          Welcome to AirBrick Design Library
        </h1>
      </div>
      <Navbar />
      <div className="w-full p-3 mb-20">
        {/* Section 1 card */}
        <div className="mt-10">
          <h1 className="text-3xl text-center font-semibold mb-5">Best of Workspace Templates</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {/* Card 1 */}
            <Link to="/showcase?tags=open_office">
              <div className="relative overflow-hidden bg-white rounded-2xl shadow-md">
                <img
                  src="https://static.airbrickinfra.com/brick-img/F/5431.jpg"
                  alt="Image 1"
                  className="w-full h-[250px] object-cover"
                />
                <div className="absolute inset-0 flex items-end justify-start p-10 bg-black bg-opacity-25 hover:bg-opacity-40 text-white transition-opacity opacity-100">
                  <div className="text-left">
                    <h2 className="text-4xl mb-4 font-bold">Open Offices</h2>
                  </div>
                </div>
              </div>
            </Link>
            {/* Card 2 */}
            <Link to="/showcase?tags=boss_cabin">
              <div className="relative overflow-hidden bg-white rounded-2xl shadow-md">
                <img
                  src="https://static.airbrickinfra.com/brick-img/AH/1953.jpg"
                  alt="Image 1"
                  className="w-full h-[250px] object-cover"
                />
                <div className="absolute inset-0 flex items-end justify-start p-10 bg-black bg-opacity-25 hover:bg-opacity-40 text-white transition-opacity opacity-100">
                  <div className="text-left">
                    <h2 className="text-4xl mb-4 font-bold">Boss Cabins</h2>
                  </div>
                </div>
              </div>
            </Link>
            {/* Card 3 */}
            <Link to="/showcase?tags=work_lounge">
              <div className="relative overflow-hidden bg-white rounded-2xl shadow-md">
                <img
                  src="https://static.airbrickinfra.com/brick-img/E/1601.jpg"
                  alt="Image 1"
                  className="w-full h-[250px] object-cover"
                />
                <div className="absolute inset-0 flex items-end justify-start p-10 bg-black bg-opacity-25 hover:bg-opacity-40 text-white transition-opacity opacity-100">
                  <div className="text-left">
                    <h2 className="text-4xl mb-4 font-bold">Work Lounges</h2>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* Section 2 card */}
        <div>
          {links
            .filter((link) => link.name === "Meeting Spaces" || link.name === "Support Spaces")
            .map((link, index) => (
              <div key={index} className="mt-20">
                <h1 className="text-3xl text-center font-semibold mb-5">Best of {link.name} Templates</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {link.sublink.map((sublink, subIndex) => (
                    <Link
                      key={subIndex}
                      to={`${process.env.REACT_APP_FRONT_URL}/showcase?tags=${sublink.name
                        .toLowerCase()
                        .replace(/\s+/g, "_")
                        .replace(/\//g, "-")}`}
                    >
                      <div className="relative overflow-hidden bg-white rounded-2xl shadow-md cursor-pointer">
                        <img
                          src={`https://static.airbrickinfra.com/brick-img${sublink?.imgLink}.jpg`}
                          alt="Image 1"
                          className="w-full h-[120px] object-cover"
                        />
                        <div className="absolute inset-0 flex items-center justify-center p-10 bg-black bg-opacity-25 hover:bg-opacity-40 text-white transition-opacity opacity-100">
                          <div className="text-left">
                            <h2 className="text-lg font-bold">{sublink.name}</h2>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </HomeLayout>
  );
};

export default Home;
