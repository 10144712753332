import "react-toastify/dist/ReactToastify.css";
import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ShowCase from "../pages/ShowCase";
import NotFoundPage from "../pages/NotFoundPage";
import Project from "../pages/Project";
import Trending from "../pages/Trending";
import Moodboard from "../pages/moodBoard/Moodboard";
import Error500 from "../pages/Error500";
import Wizard from "../pages/moodBoard/Wizard";
import ChoosePlan from "../pages/moodBoard/ChoosePlan";
import ImagePreview from "../pages/ImagePreview";
import TrendingCategorey from "../pages/TrendingCategorey";
import ImageMapper from "../pages/moodBoard/ImageMapper";
import SelfMoodBoard from "../pages/moodBoard/SelfMoodBoard";
import EditImageMapper from "../pages/moodBoard/EditImageMapper";
import AddUser from "../pages/admin/AddUser";
import UserList from "../pages/admin/UserList";
import EditUser from "../pages/admin/EditUser";
import ProjectShowcase from "../pages/project-showcase/ProjectShowcase";
import ProjectListing from "../pages/project-showcase/ProjectListing";
import ProjectShowcaseAdd from "../pages/project-showcase/admin/ProjectShowcaseAdd";
import ProjectShowcaseView from "../pages/project-showcase/admin/ProjectShowcaseView";
import ProjectShowcaseList from "../pages/project-showcase/admin/ProjectShowcaseList";
import AuthWrapper from "./AuthWrapper";
import ImageUploadAdd from "../pages/project-showcase/admin/ImageUploadAdd";
import ImageUploadEdit from "../pages/project-showcase/admin/ImageUploadEdit";

export default function AppRoutes() {
  let element = useRoutes([
    // public paths starts here
    { path: "/error500", element: <Error500 /> },
    { path: "*", element: <NotFoundPage /> },
    { path: "/", element: <Login /> },
    { path: "/project-list", element: <ProjectListing /> },
    { path: "/project-detail/:id", element: <ProjectShowcase /> },

    // protected paths starts here
    {
      path: "/",
      element: <AuthWrapper />, // Wrap all protected routes with AuthWrapper
      children: [
        {
          path: "project-showcase",
          children: [
            { index: true, element: <ProjectShowcaseList /> },
            { path: "add", element: <ImageUploadAdd /> },
            { path: ":id/re-upload", element: <ImageUploadEdit /> },
            { path: ":id/add", element: <ProjectShowcaseAdd /> },
            { path: "view/:id", element: <ProjectShowcaseView /> },
          ],
        },
        {
          path: "moodboard",
          children: [
            { index: true, element: <Wizard /> },
            { path: "image-mapper", element: <ImageMapper /> },
            { path: "image-mapper/:floorId", element: <EditImageMapper /> },
            { path: "plan", element: <ChoosePlan /> },
            { path: "floorId/:floorId", element: <Moodboard /> },
          ],
        },
        { path: "showcase", element: <ShowCase /> },
        { path: "project", element: <Project /> },
        { path: "trending", element: <Trending /> },
        { path: "trending/:category", element: <TrendingCategorey /> },
        { path: "self-moodboard/floorId/:floorId", element: <SelfMoodBoard /> },
        { path: "imageview/:mask/:id", element: <ImagePreview /> },
        { path: "add-user", element: <AddUser /> },
        { path: "edit-user/:id", element: <EditUser /> },
        { path: "user-list", element: <UserList /> },
        { path: "home", element: <Home /> },
      ],
    },
  ]);

  return element;
}
