import { useEffect, useState } from 'react';
import AiLoaderImg from "../assets/ai-loader-1.gif";

const AiLoader = () => {

    const loadingTexts = ["Loading...", "We are creating something great...", "Almost ready..."];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (currentTextIndex < loadingTexts.length - 1) {
          setCurrentTextIndex(currentTextIndex + 1);
        } else {
          setShowLoader(false);
          clearInterval(interval);
        }
      }, 1500);
  
      setTimeout(() => {
        setShowLoader(false);
        clearInterval(interval);
      }, 4500); // Hide the loader after 3 seconds
  
      return () => clearInterval(interval);
    }, [currentTextIndex]);
  
    if (!showLoader) {
      return null; // Return null when the loader should be hidden
    }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <div className="flex flex-col gap-2 justify-center">
        <img src={AiLoaderImg} alt="Loading" className="h-auto w-[500px] mx-auto" />
        <p className="text-primary-500 text-lg font-bold animate-pulse text-center">{loadingTexts[currentTextIndex]}</p>
      </div>
    </div>
  );
};

export default AiLoader;
