import React, { useState } from "react";
import { useSelector } from "react-redux";
import NoResultsComponent from "../../components/NoResultsComponent";
import * as Unicons from "@iconscout/react-unicons";
import Img from "../../components/Img";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import AiLoader from "../../components/AiLoader";

const ChoosePlan = () => {
  const floorPlanAPI = useSelector((state) => state.floorPlan.floorPlans);
  const [selectedFloor, setSelectedFloor] = useState("");
  const navigate = useNavigate();

  return (
    <Layout>
      <AiLoader />
      {floorPlanAPI.length > 0 ? (
        <section className="min-h-[95vh] flex items-center justify-center mb-10">
          <div>
            <h2 className="text-center text-3xl text-bold pt-3 mb-8">Choose on of these floorPlan Maps</h2>

            <div className="flex items-center gap-2 justify-center container mx-auto max-w-screen-xl overflow-hidden px-3">
              {floorPlanAPI.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center border-2 hover:border-primary-500 p-2 pb-1 rounded bg-white shadow-md"
                >
                  <input
                    type="radio"
                    id={item}
                    name="floor-group"
                    value={item}
                    checked={selectedFloor === item}
                    onChange={() => setSelectedFloor(item)}
                    className="hidden"
                  />
                  <label htmlFor={item} className={`relative w-full cursor-pointer rounded-lg`}>
                    <Img
                      key={index}
                      className="w-full rounded"
                      src={`https://static.airbrickinfra.com/floorplan/${item}.jpg`}
                    />
                    {selectedFloor === item && (
                      <div className="absolute top-[10px] left-[10px] bg-green-400 rounded-full flex items-center justify-center text-white">
                        <Unicons.UilCheckCircle />
                      </div>
                    )}
                    <p className="text-center font-semibold text-sm">{item}</p>
                  </label>
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-5">
              <button
                onClick={() => navigate(`/moodboard/floorId/${selectedFloor}`)}
                type="button"
                disabled={selectedFloor ? false : true}
                className="bg-primary-500 disabled:bg-gray-400  text-white py-2 px-6 rounded font-semibold mt-5 flex gap-2"
              >
                Proceed {selectedFloor && `with "${selectedFloor}"`} <Unicons.UilArrowRight />
              </button>
            </div>
          </div>
        </section>
      ) : (
        <div className="h-screen bg-white">
          <NoResultsComponent />
        </div>
      )}
    </Layout>
  );
};

export default ChoosePlan;
