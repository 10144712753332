import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  floorPlans: [],
};

const floorPlansSlice = createSlice({
  name: "floorPlan",
  initialState,
  reducers: {
    setFloorPlans: (state, action) => {
      state.floorPlans = action.payload;
    },
  },
});

export const { setFloorPlans } = floorPlansSlice.actions;
export default floorPlansSlice.reducer;
